export const IconWrapper = ({ children }) => (
  <i
    style={{
      width: "19px",
      height: "19px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {children}
  </i>
);