export const PRODUCT_TYPES = {
  expired: "expired",
  oldexpired: "oldexpired",
  preforeclosure: "preforeclosure",
  distressed: "distressed",
  fsbo: "fsbo",
  customupload: "customupload",
};

export const SUPPORTED_SOCIAL_MEDIA = [
  "facebook",
  "twitter",
  "linkedin",
  "instagram",
];
export const FALLBACK_MAP_MARKER_COLOR = "#fa7532";
export const SELECTED_LISTING_MAP_MARKER_COLOR = "#0ea5e9";


export const SUGGESTED_TAG_GROUPS = [
  {
    name: 'communication',
    tags: [
      "Attempt1",
      "Attempt2",
      "Attempt3",
      "Attempt4",
      "Attempt5",
      "Attempt6",
      "Attempt7",
      "Attempt8",
      "VM1",
      "VM2",
      "VM3",
      "VM4",
      "VM5",
      "Email1",
      "Email2",
      "Email3",
    ]
  },
  {
    name: 'funnel',
    tags: [
      "New",
      "Contacted",
      "SetAppt",
      "ListingAppt",
      "Listed",
      "Sold",
      "NotInterested",
      "WrongNumber",
      "CallBack",
      "FollowUp",
    ]
  }
];
