import React, { useCallback, useEffect, useMemo } from "react";
import { MdMyLocation } from "react-icons/md";
import { FaPrint } from "react-icons/fa";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PageLayout from "../../components/common/PageLayout";
import { Filter } from "@landvoice/icons";
import { formatNumber } from "../../utils/numbers";
import ContactCards from "./ContactCards";
import SearchPageMap from "./SearchPageMap";
import { useFeatureDiscovery } from "src/hooks/useFeatureDiscovery";
import FeatureDiscoveryTooltip from "../FeatureDiscoveryTooltip";
import { CONTACT_CARD_BUTTONS } from "../common/ContactCard";
import Checkbox from "../core/Checkbox";
import { RadioGroup, RadioGroupItem, Label, Button } from "../shadcn";
import { FieldWrapper } from "../core/FieldWrapper";
import {
  DEFAULT_GUTTER_WIDTH_DESKTOP,
  DEFAULT_GUTTER_WIDTH_MOBILE,
} from "../../constants/ui";
import {
  FaFileCsv,
  FaAngleDown,
  FaAngleUp,
  FaList,
  FaMapMarked,
} from "react-icons/fa";
import { BiSelectMultiple } from "react-icons/bi";

import { LIST_PLACEMENT } from "../common/Select";
import {
  MlsTitleAmenities,
  OwnerContacts,
} from "../../components/common/amenities";
import useIsMobile from "src/hooks/useIsMobile";
import AddNotes from "../../components/common/addNotes";
import AddTags from "../../components/common/addTags";
import NoAccessPage from "../../components/common/noAccessPage";
import ErrorPopUp from "../../components/common/errorPopUp";
import PrintListView from "../../components/common/PrintListView";
import PrintOneView from "../../components/common/PrintOneView";
import { SEARCH_PAGE_HEADER } from "../../constants/zIndexes";
import ToolbarButton from "./ToolbarButton";
import Loading from "../../components/SearchListings/Loading";
import SearchFilters from "../../components/SearchListings/SearchFilters";
import SortyBy from "../../components/SearchListings/SortBy";
import PageSize from "../../components/SearchListings/PageSize";
import Pagination from "../../components/SearchListings/Pagination";
import ActiveFiltersPanel from "../../components/SearchListings/ActiveFiltersPanel";
import {
  PrintType,
  SelectedListingsType,
  SearchResultsFormatType,
} from "../../components/SearchListings/useSearchListingsPage";
import PageTitle from "../../components/SearchListings/PageTitle";
import NoListingsFound from "../../components/SearchListings/NoListingsFound";
import SearchSummary from "./SearchSummary";
import NavigateListings from "./NavigateListings";
import { PrinterIcon } from "lucide-react";
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from "../../constants/ui";
import useSidebar from "src/hooks/useSidebar";
const gutterClassNames = `px-[${DEFAULT_GUTTER_WIDTH_MOBILE}px] md:px-[${DEFAULT_GUTTER_WIDTH_DESKTOP}px]`;

const SHOW_DOWNLOAD_WARNING_THRESHOLD = 1000;
function SearchPage({
  isPro = false,
  pageTitle = "",
  PageTitleProps = {},
  filterPanelTitle,
  apiBaseUrl = "",
  AddNotesProps = {},
  AddTagsProps = {},
  isAllowed = false,
  productName,
  provideTagsApiUrl,
  pageTitleFooter,
  printListViewPropsProvider,
  PrintListViewComponentType = PrintListView,
  printOneViewPropsProvider,
  PrintOneViewComponentType = PrintOneView,
  mlsTitleAmenitiesPropsProvider,
  ownerContactsPropsProvider,
  detailsPanelProvider,
  searchListings,
  searchListingsPage,
  provideMapMarkerColor,
  isShowMultipleSelection = true,
}) {
  const {
    filters,
    activeFilters,
    currentPage,
    pageSize,
    updateSearchParams,
    resetSearchFilters,
    searchQuery,
    printQuery,
    includePreForeclosureStatusFilter,
    includeDistressedFilters,
    includeListingStatusFilter,
    includeSearchDateTypeFilter,
    includeMLSActivesFilter,
    includeMlsFilter,
    includeLookupSourceFilter,
    includeLookupNameFilter,
    suppressPriceFilters,
    importedListingsImports,
  } = searchListings;

  const {
    selectedSort,
    optimisticUpdateListing,
    detailsListingId,
    selectedPageListing,
    detailsProperty,
    detailsOpen,
    filterOpen,
    tagsOpen,
    printType,
    setPrintType,
    toggleNotesDialog,
    toggleTagsDialog,
    detailsListingNotes,
    detailsListingTags,
    notesOpen,
    printOneViewComponentRef,
    printListViewComponentRef,
    printDetailsPropertyComponentRef,
    toggleFiltersPanel,
    printWarningIsOpen,
    togglePrintWarning,
    downloadWarningIsOpen,
    toggleDownloadWarning,
    printListings,
    downloadListings,
    isDownloadingListings,
    scrollToTop,
    updateDetailsListingNotes,
    updateDetailsListingTags,
    isToolbarOpen,
    toggleToolbar,
    getListingId,
    getListingGeoLocation,
    mapCenter,
    setMapCenter,
    searchResultsFormat,
    setSearchResultsFormat,
    getCurrentUserGeoLocation,
    isLoadingCurrentUserGeoLocation,
    selectListing,
    selectNextListing,
    selectPreviousListing,
    listingRefs,
    listingRefsById,
    isSelectionMode,
    toggleSelectionMode,
    selectedItems,
    toggleItemSelection,
    listingsForPrint,
    numPrintRecords,
    isSelectAllChecked,
    toggleSelectAll,
    selectedListingsType,
    setSelectedListingsType,
  } = searchListingsPage;

  const { data: { total_records: totalRecords, listings } = {}, isLoading } =
    searchQuery;

  const isMobile = useIsMobile();
  const { isSidebarOpen } = useSidebar();

  const isExistSearchResultsWithContacts = useMemo(
    () => (listings || []).some((listing) => listing.contacts.length > 0),
    [listings]
  );

  const features = useMemo(
    () => [
      ...(!isMobile
        ? [
            "my-location-button-desktop",
            "search-results-format-desktop",
            "navigate-listings-desktop",
          ]
        : []),
      ...(isMobile
        ? [
            "my-location-button-mobile",
            "search-results-format-mobile",
            "navigate-listings-mobile",
          ]
        : []),
      "print-and-download",
      ...(isExistSearchResultsWithContacts
        ? [
            "contact-disable-desktop",
            "contact-copy-desktop",
            "contact-call-desktop",
          ]
        : []),
    ],
    [isMobile, isExistSearchResultsWithContacts]
  );

  const { currentFeature, nextFeature, isLastFeature, shouldShowTooltip } =
    useFeatureDiscovery(features);

  const { isLoading: isLoadingListingsForPrint } = printQuery;

  useEffect(() => {
    // If user makes a selection put the selection type to selections
    // rather than All
    if (selectedItems.size > 0) {
      setSelectedListingsType(SelectedListingsType.selections);
    }
  }, [selectedItems]);

  // Watch for changes in the current listing and set the map center
  useEffect(() => {
    if (!selectedPageListing) {
      return;
    }

    const selectedPageListingGeoLocation =
      getListingGeoLocation(selectedPageListing);
    if (selectedPageListingGeoLocation) {
      setMapCenter(selectedPageListingGeoLocation);
    }
  }, [getListingGeoLocation, setMapCenter, selectedPageListing]);

  const handleSearchParamsUpdated = useCallback(
    (updates) => {
      updateSearchParams(updates);
      scrollToTop();
    },
    [updateSearchParams, scrollToTop]
  );

  const createNavigateListings = ({ isShowDiscoveryTooltip = false }) => {
    const navigateListings = (
      <NavigateListings
        onNextClick={() =>
          selectNextListing({
            // Don't open the details panel if it's not already open
            suppressDetailsOpen: !detailsOpen,
          })
        }
        onPreviousClick={() =>
          // Don't open the details panel if it's not already open
          selectPreviousListing({ suppressDetailsOpen: !detailsOpen })
        }
      />
    );
    return isShowDiscoveryTooltip ? (
      <FeatureDiscoveryTooltip
        content="Navigate through the search results one record to the next. Especially useful when looking at the map"
        isOpen
        onNext={nextFeature}
        isLast={isLastFeature}
      >
        {navigateListings}
      </FeatureDiscoveryTooltip>
    ) : (
      navigateListings
    );
  };

  const contactCardDiscoveryTooltip = useMemo(() => {
    if (currentFeature === "contact-disable-desktop") {
      return CONTACT_CARD_BUTTONS.disable;
    }
    if (currentFeature === "contact-call-desktop") {
      return CONTACT_CARD_BUTTONS.call;
    }
    if (currentFeature === "contact-copy-desktop") {
      return CONTACT_CARD_BUTTONS.copy;
    }
    return "";
  }, [currentFeature]);

  const createSearchResultsFormatSelector = (
    {
      suppressMyLocation = false,
      isShowMyLocationDiscoveryTooltip = false,
      isShowSearchResultsFormatTypeDiscoveryTooltip = false,
    } = {
      suppressMyLocation: false,
      isShowDiscoveryTooltip: false,
      isShowSearchResultsFormatTypeDiscoveryTooltip: false,
    }
  ) => {
    const myLocationButton = (
      <ToolbarButton
        icon={<MdMyLocation />}
        isLoading={isLoadingCurrentUserGeoLocation}
        onClick={async () => {
          const { data: currentUserGeoLocation } =
            await getCurrentUserGeoLocation();
          if (currentUserGeoLocation) {
            setMapCenter(currentUserGeoLocation);
            setSearchResultsFormat(SearchResultsFormatType.map);
          }
        }}
      />
    );

    const searchResultsFormatButtonGroup = (
      <ToggleButtonGroup
        value={searchResultsFormat}
        exclusive
        onChange={(event, newSearchResultsFormat) => {
          if (!newSearchResultsFormat) {
            return;
          }

          if (
            newSearchResultsFormat === SearchResultsFormatType.list &&
            selectedPageListing
          ) {
            setTimeout(() => {
              // When the user returns to list view, scroll to the current listing
              listingRefsById?.current?.[
                getListingId(selectedPageListing)
              ]?.current?.scrollIntoView({
                behavior: "instant",
                block: "center",
              });
            }, 100);
          }

          // If the user toggles to map and there is no current listing make the first
          // listing be the selected on the map
          if (
            newSearchResultsFormat === SearchResultsFormatType.map &&
            !selectedPageListing
          ) {
            selectListing(listings?.[0], true);
          }

          setSearchResultsFormat(newSearchResultsFormat);
        }}
        aria-label="text alignment"
      >
        <ToggleButton
          value={SearchResultsFormatType.list}
          aria-label={SearchResultsFormatType.list}
        >
          <FaList />
        </ToggleButton>
        <ToggleButton
          value={SearchResultsFormatType.map}
          aria-label={SearchResultsFormatType.map}
        >
          <FaMapMarked />
        </ToggleButton>
      </ToggleButtonGroup>
    );
    return (
      <div className="flex gap-3">
        {!suppressMyLocation && isShowMyLocationDiscoveryTooltip && (
          <FeatureDiscoveryTooltip
            content="Click to move the map to your location and highlight nearby listings returned in your search"
            isOpen
            onNext={nextFeature}
            isLast={isLastFeature}
          >
            {myLocationButton}
          </FeatureDiscoveryTooltip>
        )}
        {!suppressMyLocation &&
          !isShowMyLocationDiscoveryTooltip &&
          myLocationButton}
        {isShowSearchResultsFormatTypeDiscoveryTooltip && (
          <FeatureDiscoveryTooltip
            content="Use these buttons to switch between list and map views."
            isOpen
            onNext={nextFeature}
            isLast={isLastFeature}
          >
            <div id="search-results-format">
              {searchResultsFormatButtonGroup}
            </div>
          </FeatureDiscoveryTooltip>
        )}
        {!isShowSearchResultsFormatTypeDiscoveryTooltip &&
          searchResultsFormatButtonGroup}
      </div>
    );
  };
  const createPageSizeSelector = (
    { listPlacement = LIST_PLACEMENT.below } = {
      listPlacement: LIST_PLACEMENT.below,
    }
  ) => (
    <PageSize
      value={{ id: pageSize, label: pageSize, value: pageSize }}
      onChange={(option) => {
        handleSearchParamsUpdated({ pageSize: option.value, currentPage: 1 });
      }}
      listPlacement={listPlacement}
    />
  );

  const createPaginationSelector = (
    {
      suppressPageSelector = false,
      suppressNavigationButtons = false,
      listPlacement = LIST_PLACEMENT.below,
    } = {
      suppressPageSelector: false,
      suppressNavigationButtons: false,
      listPlacement: LIST_PLACEMENT.below,
    }
  ) => (
    <Pagination
      numRecords={totalRecords}
      pageSize={pageSize}
      currentPage={currentPage}
      suppressPageSelector={suppressPageSelector}
      suppressNavigationButtons={suppressNavigationButtons}
      onPageChange={(updatedPage) => {
        handleSearchParamsUpdated({ currentPage: updatedPage });
      }}
      PageProps={{
        listPlacement,
      }}
    />
  );

  return (
    <section className="hideScrollBar">
      <PageLayout detailsOpen={false} suppressGutters>
        <ErrorPopUp
          onClose={togglePrintWarning}
          open={printWarningIsOpen}
          title="Print Warning"
          data={
            "You are currently planning to print more than 1,000 records. Do you want to refine your search criteria before printing?"
          }
          proceed={printListings}
        />
        <ErrorPopUp
          onClose={toggleDownloadWarning}
          open={downloadWarningIsOpen}
          title="Download Warning"
          data={
            "You are currently planning to download more than 1,000 records. Do you want to refine your search criteria before downloading?"
          }
          proceed={downloadListings}
        />
        {filterOpen && (
          <SearchFilters
            onClose={toggleFiltersPanel}
            showListingStatus={includeListingStatusFilter}
            showSearchDateType={includeSearchDateTypeFilter}
            showMlsFilter={includeMlsFilter}
            showIncludeActiveOnMLS={includeMLSActivesFilter}
            suppressPriceFilters={suppressPriceFilters}
            showDistressedFilters={includeDistressedFilters}
            showPreForeclosureStatus={includePreForeclosureStatusFilter}
            showLookupNameFilter={includeLookupNameFilter}
            showLookupSourceFilter={includeLookupSourceFilter}
            importedListingsImports={importedListingsImports}
            onComplete={(filters) => {
              handleSearchParamsUpdated({ ...filters, currentPage: 1 });
              scrollToTop();
              toggleFiltersPanel();
            }}
            title={filterPanelTitle}
            filters={filters}
          />
        )}
        {notesOpen && (
          <AddNotes
            onClose={toggleNotesDialog}
            open
            postTo={`${apiBaseUrl}/${detailsListingId}`}
            notes={detailsListingNotes}
            onSaved={updateDetailsListingNotes}
            {...AddNotesProps}
          />
        )}
        {tagsOpen && (
          <AddTags
            onClose={toggleTagsDialog}
            open
            postTo={
              provideTagsApiUrl
                ? provideTagsApiUrl(detailsListingId)
                : `${apiBaseUrl}/${detailsListingId}/tags`
            }
            tags={detailsListingTags}
            onSaved={updateDetailsListingTags}
            {...AddTagsProps}
          />
        )}
        {detailsOpen &&
          detailsPanelProvider({
            detailsListingId,
            toggleDetailsPanel: selectListing,
            onTagsSaved: updateDetailsListingTags,
            onNotesSaved: updateDetailsListingNotes,
            optimisticUpdateListing,
            onNextListingClick: selectNextListing,
            onPreviousListingClick: selectPreviousListing,
          })}
        <div
          className={`search-page-main-content hideScrollBar ${
            detailsOpen ? "print:h-screen overflow-hidden" : ""
          }`}
        >
          <div
            className={`print:pt-0 md:sticky pb-[16px] md:left-[240px] right-0 top-0 md:top-0 bg-white ${
              detailsOpen ? "" : "print:hidden"
            } hideScrollBar `}
            style={{
              zIndex: SEARCH_PAGE_HEADER,
              left: `${
                isMobile
                  ? 0
                  : isSidebarOpen
                  ? SIDEBAR_EXPANDED_WIDTH
                  : SIDEBAR_COLLAPSED_WIDTH
              }px`,
            }}
          >
            <div className={`search-page-top ${gutterClassNames}`}>
              <PageTitle
                title={pageTitle}
                isPro={isPro}
                pageSize={pageSize}
                currentPage={currentPage}
                totalRecords={totalRecords}
                isLoading={isLoading}
                onToggleClicked={toggleToolbar}
                {...PageTitleProps}
              />
              {pageTitleFooter}

              {/* Mobile panel */}
              <div
                className={`mobile-toolbar flex-wrap mt-3 md:hidden ${
                  detailsOpen ? "" : "print:hidden"
                }`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-1 [&>*]:inline-flex gap-3">
                    <div>
                      {createSearchResultsFormatSelector({
                        isShowSearchResultsFormatTypeDiscoveryTooltip:
                          currentFeature === "search-results-format-mobile",
                        isShowMyLocationDiscoveryTooltip:
                          currentFeature === "my-location-button-mobile",
                      })}
                    </div>
                    {searchResultsFormat === SearchResultsFormatType.map && (
                      <div>
                        {createNavigateListings({
                          isShowDiscoveryTooltip:
                            currentFeature === "navigate-listings-mobile",
                        })}
                      </div>
                    )}
                    <div>
                      {createPaginationSelector({
                        suppressPageSelector: true,
                      })}
                    </div>
                  </div>
                  {/* Toggle filters panel */}
                  <ToolbarButton
                    className="md:hidden"
                    onClick={toggleToolbar}
                    icon={isToolbarOpen ? <FaAngleUp /> : <FaAngleDown />}
                  />
                </div>
                <div className="text-center p-2">
                  <SearchSummary
                    totalRecords={totalRecords}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    isShowing={!isLoading && totalRecords}
                  />
                </div>
              </div>
              {/* Filters panel */}
              <div
                className={`filters-panel flex gap-1 flex-wrap mt-3 ${
                  isToolbarOpen ? "" : "hidden md:flex"
                }`}
              >
                <div className="gap-3 flex flex-wrap [&>*]:mb-1 md:[&>*]:mb-0">
                  <div className="flex gap-3 w-full md:w-auto">
                    <div className="hidden md:block">
                      {createSearchResultsFormatSelector({
                        isShowSearchResultsFormatTypeDiscoveryTooltip:
                          currentFeature === "search-results-format-desktop",
                        isShowMyLocationDiscoveryTooltip:
                          currentFeature === "my-location-button-desktop",
                      })}
                    </div>
                    <div className="hidden md:block">
                      {createNavigateListings({
                        isShowDiscoveryTooltip:
                          currentFeature === "navigate-listings-desktop",
                      })}
                    </div>
                    <ToolbarButton
                      onClick={toggleFiltersPanel}
                      icon={<Filter />}
                    >
                      Filters
                    </ToolbarButton>
                    <SortyBy
                      value={selectedSort}
                      onChange={({ value }) => {
                        handleSearchParamsUpdated({
                          sortField: value.sort,
                          sortDir: value.sort_dir,
                        });
                      }}
                    />
                  </div>
                  <div className="flex gap-3 w-full md:w-auto [&_button]:w-full">
                    {createPageSizeSelector()}
                    <div className="block md:hidden">
                      {createPaginationSelector({
                        suppressNavigationButtons: true,
                      })}
                    </div>
                    <div className="hidden md:block">
                      {createPaginationSelector()}
                    </div>
                    {isShowMultipleSelection && (
                      <div>
                        <FeatureDiscoveryTooltip
                          content="Ability to Print and Download can be done here"
                          isOpen={shouldShowTooltip("print-and-download")}
                          onNext={nextFeature}
                          isLast={isLastFeature}
                        >
                          <ToolbarButton
                            icon={<FaPrint />}
                            onClick={toggleSelectionMode}
                            className={
                              isSelectionMode ? "bg-[#00000014]" : "bg-white"
                            }
                          />
                        </FeatureDiscoveryTooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <ActiveFiltersPanel activeFilters={activeFilters} />
              </div>
            </div>
          </div>

          {isAllowed ? (
            <section className="content-pane relative">
              <section
                className={`map-content ${
                  searchResultsFormat === SearchResultsFormatType.map
                    ? "block"
                    : "hidden"
                }`}
                style={{
                  height: "100vh",
                }}
              >
                <SearchPageMap
                  mapCenter={mapCenter}
                  listings={listings}
                  getListingGeoLocation={getListingGeoLocation}
                  selectedListing={selectedPageListing}
                  provideMapMarkerColor={provideMapMarkerColor}
                  onMarkerClick={selectListing}
                />
              </section>
              <section
                className={`search-results-content ${
                  searchResultsFormat === SearchResultsFormatType.list
                    ? "block"
                    : "hidden"
                }`}
              >
                {isLoading && (
                  <div className={`${gutterClassNames} pt-3`}>
                    <Loading />
                  </div>
                )}
                {isSelectionMode && (
                  <div
                    className={`${gutterClassNames} flex flex-row justify-between gap-1 min-h-[30px] items-center mt-5 mb-5 text-[#666666]`}
                  >
                    <FieldWrapper
                      fieldLabel="Print/Download"
                      contentClassName="px-3 pt-4 pb-4 !w-full"
                      rootClassName="!w-full"
                    >
                      <div className="flex flex-col w-full xl:flex-row items-center gap-3">
                        <div className="flex items-center space-x-2">
                          <Checkbox
                            label="Select/Deselect All On Page"
                            checked={isSelectAllChecked}
                            onCheckedChange={toggleSelectAll}
                          />
                        </div>
                        <div className="xl:flex-1 flex xl:flex-row flex-col xl:justify-end gap-3 items-center">
                          <FieldWrapper fieldLabel="Selected Contacts">
                            <RadioGroup
                              className="flex flex-row p-2"
                              value={selectedListingsType}
                              onValueChange={setSelectedListingsType}
                            >
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={SelectedListingsType.all}
                                  id="selected-listings-print-all"
                                />
                                <Label
                                  className="whitespace-nowrap"
                                  htmlFor="selected-listings-print-all"
                                >{`All (${formatNumber(
                                  totalRecords,
                                  0
                                )})`}</Label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={SelectedListingsType.selections}
                                  id="selected-listings-print-selections"
                                />
                                <Label
                                  className="whitespace-nowrap"
                                  htmlFor="selected-listings-print-selections"
                                >{`My Selections (${selectedItems.size})`}</Label>
                              </div>
                            </RadioGroup>
                          </FieldWrapper>
                          <FieldWrapper fieldLabel="Print Per Page">
                            <RadioGroup
                              value={printType}
                              onValueChange={setPrintType}
                              className="flex flex-row p-2"
                            >
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={PrintType.single}
                                  id="listings-per-page-single"
                                />
                                <Label htmlFor="listings-per-page-single">
                                  Single
                                </Label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={PrintType.multiple}
                                  id="listings-per-page-multiple"
                                />
                                <Label htmlFor="listings-per-page-multiple">
                                  Multiple
                                </Label>
                              </div>
                            </RadioGroup>
                          </FieldWrapper>
                          <div className="flex flex-row gap-3 mt-[10px]">
                            <Button
                              size="sm"
                              onClick={() => {
                                if (
                                  numPrintRecords >=
                                  SHOW_DOWNLOAD_WARNING_THRESHOLD
                                ) {
                                  togglePrintWarning();
                                } else {
                                  printListings();
                                }
                              }}
                            >
                              <PrinterIcon />
                              Print
                            </Button>
                            <Button
                              size="sm"
                              onClick={() => {
                                if (
                                  numPrintRecords >=
                                  SHOW_DOWNLOAD_WARNING_THRESHOLD
                                ) {
                                  toggleDownloadWarning();
                                } else {
                                  downloadListings();
                                }
                              }}
                            >
                              <FaFileCsv />
                              Download
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FieldWrapper>
                  </div>
                )}
                {listings?.map((listing, index) => {
                  const listingId = getListingId(listing);
                  const selectedPageListingId =
                    getListingId(selectedPageListing);
                  return (
                    <div
                      key={`search-result-${listingId}`}
                      ref={(element) => {
                        if (element) {
                          if (
                            listingRefs.current &&
                            listingRefs.current[index]
                          ) {
                            listingRefs.current[index].current = element;
                          }
                          if (
                            listingRefsById.current &&
                            listingRefsById.current[listingId]
                          ) {
                            listingRefsById.current[listingId].current =
                              element;
                          }
                        }
                      }}
                      className={`search-result ${gutterClassNames} ${
                        selectedPageListingId === listingId
                          ? "bg-slate-100"
                          : ""
                      } break-inside-avoid ${
                        index > 0 ? "py-3" : "pb-3"
                      } w-full flex flex-col flex-wrap md:flex-row gap-0 lg:gap-4 border-b-[1px] border-[#ccc] min-h-[242px]`}
                    >
                      {isSelectionMode && (
                        <div className="self-start basis-full md:basis-0">
                          <Checkbox
                            checked={selectedItems.has(listingId)}
                            onCheckedChange={() =>
                              toggleItemSelection(listingId)
                            }
                          />
                        </div>
                      )}
                      <MlsTitleAmenities
                        listing={listing}
                        onTitleClick={() => selectListing(listing)}
                        openNotes={() => toggleNotesDialog(listing)}
                        openTags={() => toggleTagsDialog(listing)}
                        onMapButtonClick={() => {
                          selectListing(listing);
                          setSearchResultsFormat(SearchResultsFormatType.map);
                        }}
                        {...mlsTitleAmenitiesPropsProvider(listing)}
                      />

                      <OwnerContacts
                        owner={ownerContactsPropsProvider(listing)}
                      />

                      <ContactCards
                        listing={listing}
                        {...(index === 0 &&
                          listing?.contacts?.length &&
                          contactCardDiscoveryTooltip && {
                            discoveryTooltip: contactCardDiscoveryTooltip,
                            isLastDiscoveryTooltip: isLastFeature,
                            onDiscoveryTooltipNextClick: nextFeature,
                          })}
                      />
                    </div>
                  );
                })}
              </section>
              {!totalRecords && !isLoading && (
                <div className={`${gutterClassNames}`}>
                  <NoListingsFound
                    onAdjustFiltersClick={toggleFiltersPanel}
                    onClearFiltersClick={resetSearchFilters}
                  />
                </div>
              )}
              {totalRecords > 0 && (
                <div
                  className={`search-results-footer ${gutterClassNames} h-[px] w-full flex my-4  ${
                    detailsOpen ? "" : "print:hidden"
                  }    gap-6 items-center justify-between sm:flex-col lg:flex-row`}
                >
                  <div className="min-w-[93px]">
                    {createPageSizeSelector({
                      listPlacement: LIST_PLACEMENT.above,
                    })}
                  </div>
                  <div className="flex justify-end ">
                    {createPaginationSelector({
                      listPlacement: LIST_PLACEMENT.above,
                    })}
                  </div>
                </div>
              )}
              <section
                ref={printListViewComponentRef}
                className="hidden print:block m-4"
              >
                {
                  <>
                    {!detailsOpen &&
                      printListViewPropsProvider &&
                      printType === PrintType.multiple &&
                      listingsForPrint?.map((listing) => {
                        return (
                          <PrintListViewComponentType
                            key={`print-list-view-${getListingId(listing)}`}
                            property={listing}
                            {...printListViewPropsProvider(listing)}
                          />
                        );
                      })}
                  </>
                }
              </section>
              <section
                ref={printDetailsPropertyComponentRef}
                className="hidden print:block m-4"
              >
                {printOneViewPropsProvider &&
                  detailsProperty &&
                  detailsOpen && (
                    <PrintOneViewComponentType
                      key={"print-details-view " + detailsProperty.id}
                      index={0}
                      property={detailsProperty}
                      {...printOneViewPropsProvider(detailsProperty)}
                    />
                  )}
              </section>
              <section
                ref={printOneViewComponentRef}
                className="hidden print:block m-4"
              >
                {
                  <>
                    {!detailsOpen &&
                      printType === PrintType.single &&
                      listingsForPrint?.map((listing, index) => {
                        return (
                          <PrintOneViewComponentType
                            key={"print-one-view " + getListingId(listing)}
                            index={index}
                            property={listing}
                            {...printOneViewPropsProvider(listing)}
                          />
                        );
                      })}
                  </>
                }
              </section>
            </section>
          ) : (
            <NoAccessPage product={productName} />
          )}
        </div>
      </PageLayout>
    </section>
  );
}

export default SearchPage;
