import {
  Bath,
  Bed,
  Grid,
  Tree,
  CalendarClock,
  Building,
  Tag,
  ViewEditNote,
  Add,
} from "@landvoice/icons";
import { Link } from "react-router-dom";
import { formatNumbersInString } from "../../utils/numbers";
import { SvgMapSolidV20, SvgZillow } from "./svg";
import DistressRate from "./distressRate";
import StatusTag from "./statusTags";
import OwnerContacts from "./ownersContact";
import { timeAgo } from "./timeAgo";
import formatPhoneNumber from "./formatPhones";
import formatDate from "./formatDate";
import formatTimestamp from "./formatTimeStamp";
import removeParamFromString from "./removeParamsFromString";
import { PRODUCT_TYPES } from "../../constants/listing";
import HorizonalDivider from "./HorizonalDivider";
import {
  getListingStatusDateUsingListingType,
  getListingStatusUsingListingType,
} from "../../utils/listings";

const InfoIcon = ({ icon, text, textSize = "12px" }) => {
  return (
    <div className={` flex min-w-[54px] gap-[4px] text-[#666666]`}>
      {icon}
      <p className={`text-[${textSize}]`}>{text}</p>
    </div>
  );
};

const Divider = ({ height = "h-4" }) => {
  return <div className={`w-[1px] ${height} bg-[#EBEBEB]`} />;
};

const HDivider = () => {
  return <HorizonalDivider className="max-w-[800px]" />;
};

const InfoText = ({ title, info, color = "text-[#666666]" }) => {
  return (
    <div className="h-[46px] min-w-[30px]  ">
      <p className="text-[12px] text-[#999999] whitespace-nowrap">{title}</p>
      <p className={`text-[16px]  leading-6 mt-2 whitespace-nowrap ${color}`}>
        {info}
      </p>
    </div>
  );
};

const InfoIconButton = ({ title, icon, buttonText, func, edit = false }) => {
  return (
    <div className=" h-[46px] min-w-[10px] cursor-pointer" onClick={func}>
      <p className="text-[12px] text-[#999999]">{title}</p>
      <div className={edit ? " editInfoButton" : "addInfoButton"}>
        {edit ? null : <Add style={{ height: "8px" }} />}
        <p className="text-[11px] font-bold text-center mx-auto leading-4 whitespace-nowrap">
          {buttonText}
        </p>
      </div>
    </div>
  );
};
const sortContacts = (contacts) => {
  return contacts.sort((a, b) => {
    if (a.star && !b.star) return -1;
    if (!a.star && b.star) return 1;
    const nameA = a?.name?.toLowerCase() ?? "";
    const nameB = b?.name?.toLowerCase() ?? "";
    return nameA.localeCompare(nameB);
  });
};

export const StatusInfo = ({ listing }) => {
  const { mls_number, distressed_level: distressedLevel, productType } = listing;
  const status = getListingStatusUsingListingType(listing);
  const statusDate = getListingStatusDateUsingListingType(listing);
  return productType === PRODUCT_TYPES.distressed && !status ? (
    <DistressRate rate={distressedLevel} />
  ) : (
    <div className="flex gap-5 text-[#999999]  pr-3 items-baseline">
      <StatusTag status={status || "Unknown"} date={statusDate || ""} />
      {mls_number && <p className="text-[14px]">MLS ID: {mls_number}</p>}
    </div>
  );
};

const MlsTitleAmenities = ({
  listing,
  title,
  beds,
  bath,
  sqrFeet,
  lotSize,
  buildDate,
  type,
  price = 0,
  dom,
  notes = [],
  tags = [],
  openNotes,
  openTags,
  status,
  publisheDAte,
  auctionDate,
  all = true,
  onTitleClick = () => {},
  hasDom = false,
  id,
  mapLink,
  isDetails = false,
  onMapButtonClick,
  suppressStatusInfo = false,
}) => {
  const titleSection = (
    <div className="flex  mb-3 group  w-fit ">
      <p className=" mt-[auto] font-semibold text-[20px] text-[#666666] text-wrap align-baseline  ">
        <span
          className={`${mapLink || onMapButtonClick ? "hover:text-[#F27935] cursor-pointer" : ""} leading-5 align-bottom`}
          onClick={onTitleClick}
        >{`${title}`}</span>
        <span className=" group-hover:inline ml-2 hover:text-[#F27935] align-bottom ">
          {mapLink && (
            <SvgMapSolidV20
              className=" text-[#666666] hover:text-[#F27935] fill-[#F27935] align-bottom inline"
              onClick={(e) => {
                if (onMapButtonClick) {
                  e.preventDefault();
                  e.stopPropagation();
                  onMapButtonClick();
                  return;
                }
                const aTag = document.createElement("a");
                aTag.setAttribute("target", `_blank`);
                aTag.href = mapLink;

                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
              }}
            />
          )}
          <SvgZillow
            onClick={() => {
              const aTag = document.createElement("a");
              aTag.setAttribute("target", `_blank`);
              aTag.href = `https://www.zillow.com/homes/${title.replace(
                " ",
                "-"
              )}`;

              document.body.appendChild(aTag);
              aTag.click();
              aTag.remove();
            }}
          />{" "}
        </span>
      </p>
    </div>
  );

  return (
    <div
      className={`md:min-w-[200px] lg:w-[550px] max-w-[90vw] break-inside-avoid ${
        all ? "xl:min-h-[218px]" : ""
      }`}
    >
      {!suppressStatusInfo && <StatusInfo listing={listing} />}

      <div className="min-h-[42px] max-w-[90vw] mb-3 lg:mb-8">
        {mapLink && isDetails ? (
          <Link
            target={isDetails ? "_blank" : "_self"}
            to={isDetails ? mapLink : null}
          >
            {titleSection}
          </Link>
        ) : (
          titleSection
        )}

        <div className=" max-w-[90vw] min-h-[16px] flex gap-3 flex-wrap">
          <InfoIcon
            gap="8px"
            icon={<Bed />}
            text={`${beds ? beds : "--"} Bed`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Bath />}
            text={`${bath ? bath : "--"} Bath`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Grid />}
            text={`${
              sqrFeet
                ? sqrFeet.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                : "--"
            }  Sq Ft`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Tree />}
            text={` LtSz ${lotSize ? formatNumbersInString(lotSize) : "--"}`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<CalendarClock />}
            text={`Built ${buildDate ? buildDate : "--"}`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Building />}
            text={`${type ? type : "--"} `}
          />
        </div>
      </div>
      {all && (
        <div className="flex md:gap-[30px] gap-3  max-w-[90vw] flex-col sm:flex-row">
          <div className="flex md:gap-[30px] gap-3 ">
            <InfoText
              title={
                status === "Notice of Trustee Sale" ||
                status === "Notice of Sale"
                  ? "Min Bid"
                  : auctionDate
                  ? "List Price"
                  : "Price"
              }
              info={price || "--"}
              color={"text-[#666666]"}
            />
            {hasDom && <InfoText title={"DOM"} info={dom} />}
            {publisheDAte && (
              <InfoText title={"Publish Date"} info={publisheDAte} />
            )}
            {auctionDate && (
              <InfoText title={"Auction Date"} info={auctionDate} />
            )}
          </div>

          <div className="flex md:gap-[30px] gap-3 ">
            <InfoIconButton
              count={notes.count}
              title={"Notes"}
              icon={<ViewEditNote style={{ height: "8px" }} />}
              buttonText={
                notes.length && notes[0]?.note && notes[0]?.note !== " "
                  ? `${notes[0]?.note.substring(0, 20)}${
                      notes[0]?.note.length > 20 ? "..." : ""
                    }`
                  : "Add Note"
              }
              func={() => {
                openNotes();
              }}
              edit={notes.length && notes[0]?.note && notes[0]?.note !== " "}
            />
            <InfoIconButton
              count={tags.count}
              title={"Tags"}
              icon={<Tag style={{ height: "8px" }} />}
              buttonText={
                tags.length
                  ? tags.slice(0, 2).join(" ").substring(0, 20)
                  : "Add Tag"
              }
              func={() => {
                openTags();
              }}
              edit={tags.length}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export {
  InfoIcon,
  Divider,
  InfoText,
  HDivider,
  sortContacts,
  MlsTitleAmenities,
  OwnerContacts,
  formatPhoneNumber,
  formatDate,
  timeAgo,
  removeParamFromString,
  formatTimestamp,
  DistressRate,
};
