export const UPLOAD_STATUSES = {
    submitted: 1,
    accepting: 2,
    accepted: 3,
    searching: 4,
    finalizing: 5,
    complete: 6,
    insufficientcredits: 7,
    error: 20,
  };
  
  export const UPLOAD_STATUSES_CONFIG = [
    { name: UPLOAD_STATUSES.submitted, label: "Submitted" },
    { name: UPLOAD_STATUSES.accepting, label: "Accepting" },
    { name: UPLOAD_STATUSES.accepted, label: "Accepted" },
    { name: UPLOAD_STATUSES.searching, label: "Searching" },
    { name: UPLOAD_STATUSES.finalizing, label: "Finalizing" },
    { name: UPLOAD_STATUSES.complete, label: "Complete" },
    { name: UPLOAD_STATUSES.insufficientcredits, label: "Insufficient Credits" },
    { name: UPLOAD_STATUSES.error, label: "Error" },
  ];
  