import React from "react";
import * as FaIcons from "react-icons/fa";
import { LiaHatWizardSolid } from "react-icons/lia";
import { FaHouseFire } from "react-icons/fa6";

export const coreProducts = [
  {
    title: "Expireds",
    path: "/expireds",
    icon: <FaIcons.FaUserClock />,
    cName: "nav-text",
  },
  {
    title: "FSBO",
    path: "/fsbo",
    icon: <FaIcons.FaSign />,
    cName: "nav-text",
  },
  {
    title: "Pre-Foreclosure",
    path: "/Preforeclosure",
    icon: <FaIcons.FaHouseDamage />,
    cName: "nav-text",
  },
  {
    title: "Neighborhood Search",
    path: "https://search.landvoice.com/",
    icon: <FaIcons.FaMap />,
    cName: "nav-text",
    window: true,
  },
  {
    title: "Scripts",
    path: "/scripts",
    icon: <FaIcons.FaFile />,
    cName: "nav-text",
    window: false,
  },
];

export const addOns = [
  {
    title: "Data Genie",
    path: "/data-genie",
    icon: <LiaHatWizardSolid style={{ fontSize: "19px" }} />,
    cName: "nav-text",
  },
  {
    title: "Distressed",
    path: "/Distressed",
    icon:<FaHouseFire />,
    cName: "nav-text",
  },
  {
    title: "Old Expired",
    path: "/OldExpired",
    icon: <FaIcons.FaUserAltSlash />,
    cName: "nav-text",
    window: false,
  },
  {
    title: "Call Capture",
    path: "https://callcapture.landvoice.com/account/dashboard",
    icon: <FaIcons.FaPhone />,
    cName: "nav-text",
    window: true,
  }
];
