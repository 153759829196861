import { Download } from "lucide-react";
import Button from "../core/Button";
import { getFilenameWithoutExtension } from "../../utils/files";
import pdfDownload from "../common/pdfDownload";
const DownloadCsvButton = ({
  disabled,
  isLoading,
  url,
  fileName,
  extension,
  onStart,
  onComplete,
}) => (
  <Button
    disabled={disabled}
    isLoading={isLoading}
    variant="secondary"
    onClick={async () => {
      onStart();
      try {
        await pdfDownload({
          url,
          fileName: getFilenameWithoutExtension(fileName),
          extension: "csv",
        });
      } finally {
        onComplete();
      }
    }}
    icon={<Download />}
  >
    Download
  </Button>
);

export default DownloadCsvButton;
