import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import useAPI from "../../../common/UseApi";
import Cookies from "universal-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
  const [isImpersonating, setIsImpersonating] = useState(false);
  // eslint-disable-next-line
  const { post, get, error } = useAPI();

  useEffect(() => {
    prelogin(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfAcceptedTOS = useCallback(async () => {
    try {
      if (isAuth) {
        const results = await get(`/api/core/terms`, () => {});
        setHasAcceptedTerms(results?.tos_accepted);
      }
    } catch (e) {
      console.error("checkIfAcceptedTOS error", e);
      throw e;
    }
  }, [isAuth, get])

  useEffect(() => {
    checkIfAcceptedTOS();
  }, [checkIfAcceptedTOS]);

  const acceptedTOS = useCallback(async () => {
    try {
      if (isAuth) {
        const resp = await post(`/api/core/terms`, { accept_tos: true })
        setHasAcceptedTerms(resp?.result === "ok");
      }
    } catch (e) {
      console.error("acceptedTOS error", e);
      throw e;
    }
  }, [isAuth, post])

  const prelogin = async (fail) => {
    try {
      const results = await get(`/api/core/account`, () => {});
      if (results?.hasOwnProperty("first_name")) {
        setUser(results);
        login(results);
      } else {
        fail();
      }
    } catch (e) {
      fail();
    }
  };

  const login = (userData) => {
    const location = window.location.pathname;
    const currentPage = location;
    setIsAuth(true);
    setUser(userData);
    if (currentPage === "/signin") {
      const redirectLink = document.createElement("a");
      redirectLink.href = "/";
      document.body.appendChild(redirectLink);
      redirectLink.click();
    }
  };

  const logout = async () => {
    await post(`/api/auth/logout`, () => {});
    const cookies = new Cookies();

    //Clear all cookies
    cookies.remove("csrftoken");
    cookies.remove("sessionid");

    setIsAuth(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuth,
        prelogin,
        login,
        logout,
        hasAcceptedTerms,
        acceptedTOS,
        isImpersonating,
        setIsImpersonating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
