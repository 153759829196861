import React, { useEffect, useState, useMemo } from "react";
import PageLayout from "../common/PageLayout";
import { Divider, HDivider } from "../common/amenities";
import { getFilenameWithoutExtension } from "src/utils/files";
import { Search, List } from "lucide-react";
import Selection from "../utils/account/select";
import { FaPlus } from "react-icons/fa";
import { PrimaryButtons } from "../common/buttons";
import useAPI from "../common/UseApi";
import FileInfoDialog from "./FileInfoDialog";
import DownloadCsvButton from "../common/DownloadCsvButton";
import Dots from "../loaders/Dots";
import Cookies from "universal-cookie";
import ErrorMessage from "../common/ErrorMessage";
import ErrorPopUp from "src/components/expireds/ErrorPopUp";
import { NavLink } from "react-router-dom";
import { useAuth } from "../utils/auth/assist/authContext";
import NoAccessPage from "../common/noAccessPage";
import { generateRandomString } from "../../utils/strings";
import FeatureDiscoveryTooltip from "../FeatureDiscoveryTooltip";
import PageTitle from "../common/PageTitle";
import LinkButton from "../core/LinkButton";
import { useFeatureDiscovery } from "src/hooks/useFeatureDiscovery";
import useMlsList from "src/hooks/useMlsList";
import formatDate from "../common/formatDate";
import useExpiredUploads from "src/hooks/useExpiredUploads";
import TableSkeleton from "../core/TableSkeleton";
import useIsMobile from "src/hooks/useIsMobile";
import PageSize from "../SearchListings/PageSize";
import SearchSummary from "../SearchListings/SearchSummary";
import Pagination from "../SearchListings/Pagination";
import { UPLOAD_STATUSES, UPLOAD_STATUSES_CONFIG } from "src/constants/upload";
import Button from "../core/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../core/Table";

const Status = ({ status }) =>
  ![
    UPLOAD_STATUSES.insufficientcredits,
    UPLOAD_STATUSES.error,
    UPLOAD_STATUSES.complete,
  ].includes(status) ? (
    <Dots width={30} height={12} />
  ) : (
    <span
      className={`${
        [UPLOAD_STATUSES.insufficientcredits, UPLOAD_STATUSES.error].includes(
          status
        )
          ? "text-red-500"
          : ""
      }`}
    >
      {UPLOAD_STATUSES_CONFIG.find(({ name }) => status === name)?.label ||
        "--"}
    </span>
  );

const Manage = () => {
  // eslint-disable-next-line
  const { get } = useAPI();
  // eslint-disable-next-line
  const [mlsDetails, setMlsDetails] = useState({});
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [errorPopUpOpen, setErrorPopUpOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [CSRFToken, setCSRFToken] = useState("0");
  const [mls, setMls] = useState(""); // Assuming mls is a string field
  const [uuid, setUuid] = useState(""); // Assuming mls is a string field
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [longErrorMessage, setLongErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [activeDownloads, setActiveDownloads] = useState([]);

  // eslint-disable-next-line
  const { user } = useAuth();
  const statusCodeToErrorMessage = {
    400: "Bad Request - The uploaded file could not be read, there were errors with one or more fields.  Check the format and try again.",
    401: "Unauthorized - You are not logged in.",
    403: "Forbidden - You do not have permission to perform this action.",
    404: "Not Found - The requested resource was not found.",
    500: "Internal Server Error - Something went wrong on our end.",
    // Add more status codes and messages as needed
  };
  const isMobile = useIsMobile();
  const {
    data: fetchedMlsList,
    isLoading: isLoadingMls,
    isError: isErrorLoadingMls,
  } = useMlsList();

  const {
    data: previousUploadsData,
    isLoading: isLoadingPreviousUploads,
    isError: isErrorLoadingPreviousUploads,
    refetch: refetchPreviousUploads,
  } = useExpiredUploads({
    pageSize,
    currentPage,
  });

  const previousUploads = useMemo(
    () => previousUploadsData?.uploads || [],
    [previousUploadsData]
  );
  const totalRecords = useMemo(
    () => previousUploadsData?.totalRecords || 0,
    [previousUploadsData]
  );
  const credits = useMemo(
    () => previousUploadsData?.credits || 0,
    [previousUploadsData]
  );

  const mlsList = useMemo(
    () => (fetchedMlsList || []).filter((mls) => mls.upload_allowed === true),
    [fetchedMlsList]
  );

  const features = useMemo(() => ["search-imported-listings"], []);

  const { currentFeature, nextFeature, isLastFeature, shouldShowTooltip } =
    useFeatureDiscovery(features);

  const onClose = ({ data }) => {
    setDetailsOpen((cur) => !cur);
    setMlsDetails(data);
  };

  const toggleErrorPopup = () => {
    setErrorPopUpOpen((cur) => !cur);
  };
  useEffect(() => {
    const cookies = new Cookies();
    const cookieValue = cookies.get("csrftoken");
    setCSRFToken(cookieValue);
    const tonken = generateRandomString(64);

    setUuid(tonken);
  }, []);
  useEffect(() => {
    const interval = setInterval(refetchPreviousUploads, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleMlsChange = (event) => {
    setMls(event);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("upload_file", file);
    formData.append("mls", mlsList.find((obj) => obj.name === mls)?.mls_id);
    formData.append("upload_token", uuid);

    try {
      setLoading(true);
      setUploadComplete(false);
      setLongErrorMessage("");
      setErrorMessage("");
      setError(false);
      const response = await fetch("/api/expired/", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRFToken": CSRFToken,
        },
      });
      setError(!true);

      if (response.ok) {
        setUploadComplete(true);
      } else {
        setErrorMessage(
          statusCodeToErrorMessage[response.status] ||
            "An unexpected error occurred. Please try again."
        );
        response.json().then((err) => {
          if (err.msg) {
            setLongErrorMessage(err.msg);
          } else {
          }
        });
        setError(true);
        // setErrorMessage(
        //   statusCodeToErrorMessage[response.status] ||
        //     "An unexpected error occurred. Please try again."
        // );
      }
    } catch (error) {
      setError(true);
      setErrorMessage("there was an error with uploading your file.");
      throw error;
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const [dragging, setDragging] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // Handle file upload here

      setFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  return (
    <PageLayout>
      <PageTitle
        title="Expired Upload"
        adjacentContent={
          <SearchSummary
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            isShowing={!isLoadingPreviousUploads && totalRecords}
          />
        }
        rightSideContent={
          <div className="flex gap-2">
            <FeatureDiscoveryTooltip
              content="Search your previously imported listings!"
              isOpen={shouldShowTooltip("search-imported-listings")}
              onNext={nextFeature}
              isLast={isLastFeature}
            >
              <LinkButton
                variant="secondary"
                to="/expireds/search"
                icon={<Search />}
              >
                Expireds
              </LinkButton>
            </FeatureDiscoveryTooltip>
          </div>
        }
      />
      <div className="min-h-[120px] mb-[16px] pb-3">
        <FileInfoDialog
          open={detailsOpen}
          data={mlsDetails}
          onClose={onClose}
        />

        <ErrorPopUp
          onClose={toggleErrorPopup}
          open={errorPopUpOpen}
          data={longErrorMessage || errorMessage}
        />
        {user?.expired_upload ? (
          <section>
            <div
              onDragEnter={handleDragIn}
              onDragLeave={handleDragOut}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              className={`w-full border-2   min-h-[364px] rounded-2xl p-8 flex gap-16 flex-wrap mt-3 ${
                dragging
                  ? "border-dashed border-[#f27a35a6] border-3"
                  : "border-[#EBEBEB]"
              }`}
            >
              <div className="max-h-[300px] w-[231px] flex flex-col gap-2 justify-center items-center">
                <p className=" leading-6 text-[20px] text-[#999999]">
                  Lookups Remaining
                </p>
                <p className=" leading-[57.6px] text-[48px] text-[#666666]">
                  {credits?.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2, // You can adjust this based on your requirements
                  })}
                </p>
              </div>
              <Divider height="max-h-[500px]" />
              <div className="flex flex-col gap-4 max-w-[458px]">
                <div className=" flex gap-4 max-w-[458px] items-center  h-7">
                  <p className=" text-[16px] text-[#666666] whitespace-nowrap leading-7 font-bold">
                    Upload a File
                  </p>

                  <HDivider />
                </div>
                {error ? (
                  <ErrorMessage
                    message={
                      <p>
                        {errorMessage}{" "}
                        {longErrorMessage ? (
                          <span
                            className=" font-semibold underline cursor-pointer"
                            onClick={toggleErrorPopup}
                          >
                            {" "}
                            see more{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    }
                  />
                ) : null}
                {uploadComplete ? (
                  <div className=" min-h-[44px] w-full bg-[#c3fac9] mb-8 rounded-md p-3">
                    <p className=" text-[14px] font-[400] leading-[20px] text-[#19a82c]">
                      {
                        "Upload completed, your file is currently being processed."
                      }
                    </p>
                  </div>
                ) : null}
                <div className=" flex flex-col gap-4">
                  {/* --------------*******------------- */}
                  <div className="flex flex-col gap-1 max-w-[425px]">
                    <p className=" font-semibold text-[14px] leading-5 text-[#666666] h-5">
                      1. Select your MLS{" "}
                    </p>

                    <Selection
                      rounded={"8px"}
                      SelectionColor={false}
                      selecionHeight={"36px"}
                      setIsActive={() => {}}
                      list={mlsList}
                      initialSelection={0}
                      flowWidth={true}
                      onValueChange={handleMlsChange}
                    />
                  </div>
                  {/* ------------ */}

                  <div className="flex flex-col gap-1 max-w-[320px]">
                    <p className=" font-semibold text-[14px] leading-5 text-[#666666]">
                      2. Select your file{" "}
                    </p>

                    <p className=" text-[12px] leading-4 text-[#999999]">
                      Files must be in the .csv format. The exact format depends
                      on the output of your MLS export.
                    </p>
                    <div className=" flex gap-5 w-full lg:min-w-[500px] md:flex-row flex-col">
                      <label className="flex w-[125px] h-10 items-center justify-center bg-[#E8EDF0] text-[#666666] px-3 py-2 rounded-lg gap-2 cursor-pointer">
                        <FaPlus />
                        <span className="text-[14px] font-semibold leading-7">
                          Choose File
                        </span>
                        <input
                          onChange={handleFileChange}
                          type="file"
                          className="hidden"
                        ></input>
                      </label>
                      <p className=" text-[14px] text-[#666]"> {file?.name}</p>
                    </div>
                  </div>

                  <HDivider />
                  <div className=" flex gap-4">
                    <div className=" min-w-[100px] gap-4 flex">
                      <PrimaryButtons
                        label={"Upload"}
                        minWidth={100}
                        onClick={handleSubmit}
                        downloading={loading}
                      />
                    </div>
                    <p className=" text-[12px] leading-4 text-[#999999]">
                      Please only click the upload button once. Clicking
                      multiple times may result in the upload being submitted
                      multiple times.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-full overflow-x-auto mt-8">
              {isLoadingPreviousUploads && (
                <TableSkeleton rows={isMobile ? 6 : 12} fileColumnsKeys={7} />
              )}
              {!isLoadingPreviousUploads && (
                <Table condensed="true">
                  <TableHeader className="sticky top-0 z-10">
                    <TableRow>
                      {[
                        "Name",
                        "Uploaded",
                        "MLS",
                        "Status",
                        "Total",
                        "Subscribed",
                        "Actions",
                      ].map((heading, index) => (
                        <TableHead key={`previous-uploads-header-${index}`}>
                          {heading}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {previousUploads?.map((row, index) => (
                      <TableRow key={`map-fields-${index}`}>
                        <TableCell>{row.uploaded_file || "--"}</TableCell>
                        <TableCell>
                          {formatDate(row.complete_date) || "--"}
                        </TableCell>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                          <Status status={row.step} />
                        </TableCell>
                        <TableCell>{row.total_records}</TableCell>
                        <TableCell>{row.subscribed_records}</TableCell>
                        <TableCell>
                          <div className="flex gap-2">
                            <LinkButton
                              variant="secondary"
                              icon={<List />}
                              to={`/expireds/search?batchId=${row.id}`}
                            >
                              View
                            </LinkButton>
                            <DownloadCsvButton
                              url={`/api/expired/listing/csv?batch_id=${row?.id}`}
                              fileName={row?.uploaded_file}
                              disabled={
                                row.step !== UPLOAD_STATUSES.complete
                              }
                              isLoading={activeDownloads.includes(row.id)}
                              onStart={() => {
                                setActiveDownloads((prev) => {
                                  return [...prev, row.id];
                                });
                              }}
                              onComplete={() => {
                                setActiveDownloads((prev) => [
                                  ...prev.filter((id) => id !== row.id),
                                ]);
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
            <div className="flex justify-between items-center mt-4">
                <PageSize
                  value={{ id: pageSize, label: pageSize, value: pageSize }}
                  onChange={(option) => {
                    setPageSize(option.value);
                    setCurrentPage(1);
                  }}
                />
                <Pagination
                  numRecords={totalRecords}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={(updatedPage) => {
                    setCurrentPage(updatedPage);
                  }}
                />
              </div>
          </section>
        ) : (
          <NoAccessPage product="Upload Expireds" />
        )}
      </div>
    </PageLayout>
  );
};

export default Manage;
