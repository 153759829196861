import React, { useCallback, useEffect, useState, useMemo } from "react";
import useAsyncMemo from "../../hooks/useAsyncMemo";
import { createDatePrettyName, isBlank } from "../../utils/strings";
import PageLayout from "../common/PageLayout";
import { useNavigate } from "react-router";
import StepView from "./StepView";
import { useToast } from "../shadcn/useToast";
import SearchImportedListings from "./SearchImportedListings";
import { STEPS, STEPS_CONFIG, LOOKUP_TYPES } from "./constants";
import { convertFileToJson } from "./utils";
import ConfigureUpload from "./ConfigureUpload";
import Review from "./Review";
import MapFields from "./MapFields";
import {
  useUploadMutation,
  useGetCustomUploadsQueryCreditsQuery,
  checkIsValidImportNameQuery,
} from "../../hooks/useCustomUploads";

const fieldsConfig = [
  { label: "First Name", key: "first_name", isRecommended: true },
  { label: "Last Name", key: "last_name", isRecommended: true },
  { label: "Address", key: "full_address" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip Code", key: "postal_code" },
  {
    label: "Primary Phone",
    mappedKey: "",

    key: "primary_phone_number",
  },
  {
    label: "Secondary Phone",
    mappedKey: "",

    key: "secondary_phone_number",
  },
  { label: "Email", key: "email" },
  { label: "Price", key: "price" },
  { label: "Beds", key: "bedrooms" },
  { label: "Baths", key: "baths" },
  { label: "Square Footage", key: "square_footage" },
  { label: "Lots Size", key: "lot_size" },
];

const createMapping = () =>
  fieldsConfig.map(({ key }) => ({
    mappedKey: "",
    key,
  }));

const createDefaultImportName = () => createDatePrettyName("Import");

const ManageImports = () => {
  const { mutateAsync: upload } = useUploadMutation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [jsonData, setJsonData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [currentStep, setCurrentStep] = useState(STEPS.configureUpload);
  const [listName, setListName] = useState(createDefaultImportName());
  const [listSource, setListSource] = useState();
  const [lookupType, setLookupType] = useState(LOOKUP_TYPES.reverseAddress);
  const [mapping, setMapping] = useState(createMapping());
  const [currentUploadId, setCurrentUploadId] = useState();
  const { data: userCredits } = useGetCustomUploadsQueryCreditsQuery();

  const numRecords = useMemo(() => jsonData?.length || 0, [jsonData]);

  const augmentedFieldsConfig = useMemo(
    () =>
      fieldsConfig
        .map((field) => {
          const { key } = field;
          switch (key) {
            case "primary_phone_number":
              return {
                ...field,
                isRequired: lookupType === LOOKUP_TYPES.reversePhone,
              };
            case "full_address":
            case "city":
            case "state":
            case "postal_code":
              return {
                ...field,
                isRequired: lookupType === LOOKUP_TYPES.reverseAddress,
              };
            default:
              return {
                ...field,
                isRequired: false,
              };
          }
        })
        .sort((field1, field2) => {
          const { isRequired: isRequired1, isRecommended: isRecommended1 } =
            field1;
          const { isRequired: isRequired2, isRecommended: isRecommended2 } =
            field2;
          if (isRequired1 && !isRequired2) {
            return -1;
          }
          if (isRequired2 && !isRequired1) {
            return 1;
          }
          if (isRecommended1 && !isRecommended2) {
            return -1;
          }
          if (isRecommended2 && !isRecommended1) {
            return 1;
          }
          return 0;
        }),
    [fieldsConfig, lookupType]
  );

  const stepsInfo = useAsyncMemo(async () => {
    const isValidImportName =
      !isBlank(listName) && (await checkIsValidImportNameQuery(listName));

    return STEPS_CONFIG.map((stepConfig) => {
      let navigateToPermitted;
      switch (stepConfig.name) {
        case STEPS.review:
          const requiredFieldKeys = augmentedFieldsConfig
            .filter(({ isRequired }) => isRequired)
            .map(({ key }) => key);
          const hasMissingFields = mapping.some(
            ({ mappedKey, key }) =>
              requiredFieldKeys.includes(key) && !mappedKey
          );
          navigateToPermitted =
            !!fileName && !hasMissingFields && isValidImportName;
          break;
        case STEPS.mapFields:
          navigateToPermitted =
            !!fileName && userCredits >= numRecords && isValidImportName;
          break;
        default:
          navigateToPermitted = true;
          break;
      }

      return {
        ...stepConfig,
        navigateToPermitted,
      };
    });
  }, [
    currentStep,
    augmentedFieldsConfig,
    fileName,
    mapping,
    numRecords,
    userCredits,
    listName,
  ]);

  const currentStepInfo = useMemo(
    () => STEPS_CONFIG.find(({ name }) => name === currentStep),
    [currentStep]
  );

  const fileColumnsKeys = useMemo(
    () => Object.keys(jsonData?.[0] || {}),
    [jsonData]
  );

  const handleStepClick = useCallback(
    (step) => {
      setCurrentStep(step);
    },
    [setCurrentStep]
  );

  const handleSubmitClick = useCallback(async () => {
    try {
      upload({
        config: {
          mapping,
          fileColumnsKeys,
          listSource: "" || listSource?.value || "",
          fileName,
          listName,
          lookupType,
        },
        jsonData,
      });
      navigate("/data-genie/previous");
      toast({
        title: `Lookup submitted`,
        description: `${listName || fileName} has been submitted for lookup`,
      });
    } catch (error) {
      console.error(error);
    }
  }, [upload, mapping, listSource, fileName, listName, jsonData]);

  const handleCancelClick = useCallback(() => {
    navigate("/data-genie/previous");
  }, []);

  const handleFileSelected = useCallback(
    async (file) => {
      setFileName(file.name);
      const jsonData = await convertFileToJson(file);
      if (!jsonData) {
        return;
      }
      setJsonData(jsonData);
      if (jsonData.length > userCredits) {
        // Show error toast
      }
    },
    [setFileName, setJsonData, userCredits]
  );

  const handleMappingChange = useCallback(
    ({ key, mappedKey }) => {
      setMapping((prevMapping) =>
        prevMapping.map((field) => {
          return field.key === key ? { ...field, mappedKey } : field;
        })
      );
    },
    [jsonData, setMapping]
  );

  return (
    <>
      <PageLayout zeroMargin={false}>
        {[STEPS.review, STEPS.configureUpload, STEPS.mapFields].includes(
          currentStep
        ) && (
          <StepView
            onStepClick={handleStepClick}
            stepsInfo={stepsInfo}
            onCancelClick={handleCancelClick}
            isLastStep={currentStep === STEPS.review}
            isFirstStep={currentStep === STEPS.configureUpload}
            onPrevClick={() => setCurrentStep(currentStep - 1)}
            onNextClick={() => {
              setCurrentStep(currentStep + 1);
            }}
            currentStep={currentStep}
            currentStepInfo={currentStepInfo}
          >
            {currentStep === STEPS.configureUpload && (
              <ConfigureUpload
                importNameValidator={checkIsValidImportNameQuery}
                numRecords={numRecords}
                jsonData={jsonData}
                onFileSelected={handleFileSelected}
                onListNameChange={setListName}
                fileName={fileName}
                userCredits={userCredits}
                listName={listName}
                fileColumnsKeys={fileColumnsKeys}
                listSource={listSource}
                onListSourceChange={setListSource}
                lookupType={lookupType}
                onLookupTypeChange={setLookupType}
              />
            )}
            {currentStep === STEPS.mapFields && (
              <MapFields
                fileColumnsKeys={fileColumnsKeys}
                mapping={mapping}
                fieldsConfig={augmentedFieldsConfig}
                onMappingChange={handleMappingChange}
              />
            )}
            {currentStep === STEPS.review && (
              <Review
                onSubmitClick={handleSubmitClick}
                mapping={mapping}
                fieldsConfig={augmentedFieldsConfig}
                numRecords={jsonData.length}
              />
            )}
          </StepView>
        )}
      </PageLayout>
    </>
  );
};

export default ManageImports;
