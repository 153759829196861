import React, { useState, useEffect, useCallback, useMemo } from "react";
import { SecondaryButtons, PrimaryButtons } from "./buttons";
import ScrollContainer from "../core/ScrollContainer";
import Modal from "./Modal";
import useAPI from "./UseApi";
import Badge from "../core/Badge";
import { SUGGESTED_TAG_GROUPS } from "src/constants/listing";

import { Close } from "@landvoice/icons";
import { PrimaryInput } from "./inputFields";

const AddTags = ({
  onClose,
  open,
  tags = [],
  postTo,
  onSaved = (updatedTags) => {},
}) => {
  const [currentTags, setCurrentTags] = useState(tags);

  const lowerCasedCurrentTags = useMemo(
    () => currentTags.map((tag) => tag.toLowerCase()),
    [currentTags]
  );

  const [newTag, setNewTag] = useState(``);
  const { post } = useAPI();

  const clear = () => {
    setNewTag("");

    onClose();
  };

  const addTag = (newTag) => {
    const sanitizedTag = newTag.replace(/\s/g, "");
    if (sanitizedTag === "") {
      return;
    }

    const lowerCasedTag = sanitizedTag.toLowerCase();
    lowerCasedCurrentTags.find((tag) => tag === lowerCasedTag)
      ? setCurrentTags([...currentTags])
      : setCurrentTags([...currentTags, lowerCasedTag]);
  };

  useEffect(() => {
    setCurrentTags(tags);
    // eslint-disable-next-line
  }, [tags, open]);

  const saveTags = () => {
    post(
      postTo,
      {
        tags: currentTags,
      },
      () => {
        onSaved(currentTags);
        clear();
      }
    );
  };

  const removeTag = (tag) => {
    setCurrentTags([...currentTags.filter((t) => t !== tag)]);
  };

  return (
    <Modal
      onClose={clear}
      open={open}
      mxWidth={"max-w-[600px]"}
      title="Edit Tags"
    >
      <div className="  max-w-[552px] min-h-[176px] py-4 ">
        <div className="flex justify-between gap-2 h-9">
          <PrimaryInput
            autoFocus
            value={newTag}
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
            placeHolder={"Enter a tag"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (e.target.value) {
                  addTag(e.target.value);
                  setNewTag("");
                }
              }
            }}
          />
          <SecondaryButtons
            label="Add Tag"
            onClick={() => {
              if (newTag) {
                addTag(newTag);
                setNewTag("");
              }
            }}
          />
        </div>
        <div className="mt-8">
          <p class="font-semibold w-fit whitespace-nowrap text-[14px] text-[#999999] leading-6">
            Tags
          </p>
          {currentTags.length ? (
            <ScrollContainer>
              <div className=" flex flex-wrap gap-2 mt-1 max-h-[200px] pr-[8px] min-[40px]">
                {currentTags.map((tag, index) => {
                  return (
                    <Badge
                      key={`tag ${index}`}
                      className="text-[#666666] cursor-pointer"
                      variant="outlined"
                      onClose={() => removeTag(tag)}
                    >
                      {tag}
                    </Badge>
                  );
                })}
              </div>
            </ScrollContainer>
          ) : (
            <p className="text-[#C2C2C2] italic leading-5 text-[14px] mt-1 min-[40px]">
              No tags added.
            </p>
          )}{" "}
        </div>
        <div className="mt-8">
          <p class="font-semibold w-fit whitespace-nowrap text-[14px] text-[#999999] leading-6">
            Suggested Tags
          </p>
          <div className="flex flex-col gap-2 mt-1">
            {SUGGESTED_TAG_GROUPS.map(
              ({ name, tags: suggestedTags }, index) => {
                const filteredTags = suggestedTags.filter(
                  (suggestedTag) =>
                    !lowerCasedCurrentTags.includes(suggestedTag.toLowerCase())
                );
                return (
                  filteredTags.length && (
                    <div className="flex flex-wrap gap-2 pr-[8px] w-[90%]">
                      {filteredTags.map((tag, index) => {
                        return (
                          <Badge
                            key={`suggested-tag-${index}`}
                            className="text-[#666666]"
                            variant="outlined"
                            onClick={() => {
                              addTag(tag);
                            }}
                          >
                            {tag}
                          </Badge>
                        );
                      })}
                    </div>
                  )
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className=" flex justify-between mt-8">
        <SecondaryButtons label={"Cancel"} onClick={clear} />
        <PrimaryButtons label={"Save"} onClick={saveTags} />
      </div>
    </Modal>
  );
};

export default AddTags;
