import ButtonLink from "../common/ButtonLink";
import { CgPlayListSearch } from "react-icons/cg";
import { Stack } from "@mui/material";

const NoListingsFound = ({ onAdjustFiltersClick, onClearFiltersClick }) => (
  <Stack className="font-[400] text-[16px] text-[#999999]" direction="row" alignItems="center" justifyContent="center" padding="20px">
    <CgPlayListSearch color="#999999" size={70} />
    &nbsp;
    Try
    &nbsp;
    <ButtonLink onClick={onAdjustFiltersClick}>
      change
    </ButtonLink>
    &nbsp;or&nbsp;
    <ButtonLink onClick={onClearFiltersClick}>reset</ButtonLink>
    &nbsp;
    your filters
  </Stack>
);

export default NoListingsFound;
