import { ReactNode } from "react";
import { Loader2 } from "lucide-react"
import { Button as BaseButton, ButtonProps as BaseButtonProps } from "../shadcn"

export type ButtonProps = BaseButtonProps & { icon?: ReactNode, isLoading?: boolean }
const Button = ({ children, icon, isLoading, disabled = false, ...otherProps }: ButtonProps) =>
    <BaseButton
        size="sm"
        disabled={isLoading || disabled}
        {...otherProps}
    >
        <div className="flex items-center gap-1 relative">
            
            <div className={`flex flex-row gap-2 items-center ${isLoading ? 'invisible': 'visible'}`}>{icon}{children}</div>
            {isLoading && <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                <Loader2 className="animate-spin" />
            </div>}
        </div>
    </BaseButton>


export default Button;