import { Import } from "lucide-react";
import SearchPage from "../SearchListings/SearchPage";
import { useAuth } from "../utils/auth/assist/authContext";
import { PropertyTypeEnum, StatusTypeEnum } from "../../data/selectionData";
import useSearchListings from "../SearchListings/useSearchListings";
import useSearchListingsPage from "../SearchListings/useSearchListingsPage";
import ExpiredDetails from "../SearchListings/ExpiredDetails";
import FeatureDiscoveryTooltip from "../FeatureDiscoveryTooltip";
import LinkButton from "../core/LinkButton";
import useAccount from "../../hooks/useAccount";
import { useMemo } from "react";
import { getExpiredListingMapMarkerColor, getExpiredListingTitle } from "../../utils/listings";
import { PRODUCT_TYPES } from "../../constants/listing";
import { getListingPrice, getFormattedPrice } from "../../utils/listings";
import { useFeatureDiscovery } from "src/hooks/useFeatureDiscovery";
const Search = () => {
  const { user } = useAuth();

  const {
    data: account,
    isLoading: isLoadingAccount,
    isError: isErrorLoadingAccount,
  } = useAccount();

  const searchListings = useSearchListings({
    productType: PRODUCT_TYPES.expired,
    searchQueryKey: "getExpiredListings",
    searchUrl: "/api/expired/listing",
    includeListingStatusFilter: true,
    includeBatchIdFilter: true,
    includeMlsFilter: true,
    includeSearchDateTypeFilter: true,
  });

  const features = useMemo(() => ["manage-lookups"], []);

  const { currentFeature, nextFeature, isLastFeature, shouldShowTooltip } =
    useFeatureDiscovery(features);

  const hasExpiredsUploadLicense = useMemo(() => {
    if (isLoadingAccount || isErrorLoadingAccount) {
      return false;
    }
    return account.expired_upload;
  }, [account, isLoadingAccount, isErrorLoadingAccount]);

  const isExpiredPro = useMemo(() => {
    if (isLoadingAccount || isErrorLoadingAccount) {
      return false;
    }
    return account.expired_pro;
  }, [account, isLoadingAccount, isErrorLoadingAccount]);

  const searchListingsPage = useSearchListingsPage({
    searchQueryKey: "getExpiredListings",
    exportedCsvFileName: "expiredlisting",
    searchListings,
  });

  return (
    <SearchPage
      searchListings={searchListings}
      searchListingsPage={searchListingsPage}
      productName="Expireds"
      pageTitle="Expireds"
      PageTitleProps={{
        rightSideContent: hasExpiredsUploadLicense ? (
          <div className="flex gap-2">
            <FeatureDiscoveryTooltip
              content="Upload your expired data and view your previous lookups!"
              isOpen={shouldShowTooltip("manage-lookups")}
              onNext={nextFeature}
              isLast={isLastFeature}
            >
              <LinkButton
                variant="secondary"
                to="/expireds/manage"
                icon={<Import />}
              >
                Expired Upload
              </LinkButton>
            </FeatureDiscoveryTooltip>
          </div>
        ): null,
      }}
      productName="Expireds"
      isPro={isExpiredPro}
      apiBaseUrl="/api/expired/listing"
      AddNotesProps={{ where: "expired" }}
      isAllowed={!!user?.expireds}
      provideMapMarkerColor={({ status }) =>
        getExpiredListingMapMarkerColor(status)
      }
      detailsPanelProvider={({
        detailsListingId,
        toggleDetailsPanel,
        ...otherProps
      }) => {
        return (
          <ExpiredDetails
            {...otherProps}
            productType={PRODUCT_TYPES.expired}
            listingId={detailsListingId}
            onClose={toggleDetailsPanel}
          />
        );
      }}
      mlsTitleAmenitiesPropsProvider={(property) => ({
        id: property?.id,
        title: getExpiredListingTitle(property),
        beds: property?.bedrooms,
        bath: property?.baths,
        sqrFeet: property?.square_feet.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
        lotSize: property?.acres,
        buildDate: property?.year_built,
        type: PropertyTypeEnum[property?.property_type],
        status: StatusTypeEnum[property?.status],
        price: getListingPrice(property)
          ? getFormattedPrice(getListingPrice(property))
          : "--",
        dom: property?.days_on_market,
        notes: property?.notes,
        tags: property?.tags,
        hasDom: true,
        where: "Expired",
        mapLink: `https://www.google.com/maps/place/${property?.property?.street_address}+${property?.property?.city}+${property?.property?.state}+${property?.property?.postal_code}`,
      })}
      ownerContactsPropsProvider={(property) => ({
        ...property?.owner_information,
        ...property?.social_media,
        email: property?.social_media?.email,
      })}
      printOneViewPropsProvider={(printProperty) => ({
        title: getExpiredListingTitle(printProperty),
        beds: printProperty?.bedrooms,
        bath: printProperty?.baths,
        sqrFeet: printProperty?.square_feet,
        lotSize: printProperty?.acres,
        buildDate: printProperty?.year_built,
        type: PropertyTypeEnum[printProperty?.property_type],
        price: getFormattedPrice(getListingPrice(printProperty)),
        dom: printProperty?.days_on_market,
      })}
      printListViewPropsProvider={(property) => ({
        title: getExpiredListingTitle(property),
        beds: property?.bedrooms,
        bath: property?.baths,
        sqrFeet: property?.square_feet,
        lotSize: property?.acres,
        buildDate: property?.year_built,
        type: PropertyTypeEnum[property?.property_type],
        price: getFormattedPrice(getListingPrice(property)),
        dom: property?.days_on_market,
      })}
    />
  );
};
export default Search;
