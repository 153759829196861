import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Plus, List, Search } from "lucide-react";
import PageLayout from "../common/PageLayout";
import PageTitle from "../common/PageTitle";
import NoAccessPage from "../common/noAccessPage";
import DownloadCsvButton from "../common/DownloadCsvButton";
import { NavLink } from "react-router-dom";
import LinkButton from "../core/LinkButton";
import formatDate from "../common/formatDate";
import {
  UPLOAD_STATUSES,
  UPLOAD_STATUSES_CONFIG,
} from "../../constants/upload";
import createLoopingArray from "src/utils/createLoopingArray";
import ErrorPopUp from "../expireds/ErrorPopUp";
import Button from "../core/Button";
import { useGetCustomUploadsQuery } from "src/hooks/useCustomUploads";
import { convertFileToJson } from "./utils";
import Pagination from "../SearchListings/Pagination";
import PageSize from "../SearchListings/PageSize";
import TableSkeleton from "../core/TableSkeleton";
import Dots from "../loaders/Dots";
import SearchSummary from "../SearchListings/SearchSummary";
import { STEPS } from "./constants";
import useIsMobile from "src/hooks/useIsMobile";
import { useFeatureDiscovery } from "src/hooks/useFeatureDiscovery";
import FeatureDiscoveryTooltip from "../FeatureDiscoveryTooltip";
import { useAuth } from "../utils/auth/assist/authContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../core/Table";

const Status = ({ status }) =>
  ![
    UPLOAD_STATUSES.insufficientcredits,
    UPLOAD_STATUSES.error,
    UPLOAD_STATUSES.complete,
  ].includes(status) ? (
    <Dots width={30} height={12} />
  ) : (
    <span
      className={`${
        [UPLOAD_STATUSES.insufficientcredits, UPLOAD_STATUSES.error].includes(
          status
        )
          ? "text-red-500"
          : ""
      }`}
    >
      {UPLOAD_STATUSES_CONFIG.find(({ name }) => status === name)?.label ||
        "--"}
    </span>
  );

const PreviousImports = ({ onFileSelected }) => {
  const { user } = useAuth();
  const [errorPopUpOpen, setErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [longErrorMessage, setLongErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const isMobile = useIsMobile();
  const [activeDownloads, setActiveDownloads] = useState([]);
  const { data, refetch, isLoading } = useGetCustomUploadsQuery({
    pageSize,
    currentPage,
  });

  const features = useMemo(
    () => [
      "search-imported-listings",
      "view-imported-listings",
      "download-imported-listings",
    ],
    []
  );

  const { currentFeature, nextFeature, isLastFeature, shouldShowTooltip } =
    useFeatureDiscovery(features);

  const handleErrorPopUp = () => {
    setErrorPopUpOpen((cur) => !cur);
  };

  const createViewListingsButton = (row, isNeedsTooltip) => {
    const button = (
      <LinkButton
        variant="secondary"
        icon={<List />}
        to={`/data-genie/search?lookupIds=${row.id}&fromDate=&toDate=`}
      >
        View
      </LinkButton>
    );

    return isNeedsTooltip ? (
      <FeatureDiscoveryTooltip
        content="View the data for this lookup!"
        isOpen={shouldShowTooltip("view-imported-listings")}
        onNext={nextFeature}
        isLast={isLastFeature}
      >
        {button}
      </FeatureDiscoveryTooltip>
    ) : (
      button
    );
  };

  const createDownloadListingsButton = useCallback(
    (row, isNeedsTooltip) => {
      const button = (
        <DownloadCsvButton
          url={`/api/customupload/listing/csv?list_id=${row?.id}`}
          fileName={row?.name}
          disabled={row.status !== UPLOAD_STATUSES.complete}
          isLoading={activeDownloads.includes(row.id)}
          onStart={() => {
            setActiveDownloads((prev) => {
              return [...prev, row.id];
            });
          }}
          onComplete={() => {
            setActiveDownloads((prev) => [
              ...prev.filter((id) => id !== row.id),
            ]);
          }}
        />
      );
      return isNeedsTooltip ? (
        <FeatureDiscoveryTooltip
          content="Download the data for this lookup!"
          isOpen={shouldShowTooltip("download-imported-listings")}
          onNext={nextFeature}
          isLast={isLastFeature}
        >
          {button}
        </FeatureDiscoveryTooltip>
      ) : (
        button
      );
    },
    [shouldShowTooltip, nextFeature, isLastFeature]
  );

  useEffect(() => {
    const interval = setInterval(refetch, 10000); // 10000 milliseconds = 10 seconds
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const startNewLookupButton = (
    <LinkButton variant="secondary" to="/data-genie/start" icon={<Plus />}>
      Start Lookup
    </LinkButton>
  );

  const previousImports = useMemo(() => data?.imports || [], [data]);
  const totalRecords = useMemo(() => data?.totalRecords || 0, [data]);

  return (
    <PageLayout zeroMargin={false}>
      <ErrorPopUp
        open={errorPopUpOpen}
        data={longErrorMessage || errorMessage}
        handleOpen={handleErrorPopUp}
      />
      <PageTitle
        title="Previous Lookups"
        subtitle="Data Genie"
        adjacentContent={
          <SearchSummary
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            isShowing={!isLoading && totalRecords}
          />
        }
        rightSideContent={
          <div className="flex gap-2">
            <FeatureDiscoveryTooltip
              content="Search your previously imported listings!"
              isOpen={shouldShowTooltip("search-imported-listings")}
              onNext={nextFeature}
              isLast={isLastFeature}
            >
              <LinkButton
                variant="secondary"
                to="/data-genie/search"
                icon={<Search />}
              >
                Imported Contacts
              </LinkButton>
            </FeatureDiscoveryTooltip>
            {startNewLookupButton}
          </div>
        }
      />

      {!!user?.customupload ? (
        <section>
          {isLoading && (
            <TableSkeleton rows={isMobile ? 6 : 12} fileColumnsKeys={7} />
          )}
          {!isLoading && !previousImports?.length && (
            <div className="flex flex-col justify-center items-center p-12 gap-3">
              <p className="text-[#666666] text-lg">No lookups yet</p>
              {startNewLookupButton}
            </div>
          )}
          {!isLoading && !!previousImports?.length && (
            <>
              <div className=" hidden md:block w-full overflow-auto mt-8 h-[700px]">
                <Table condensed="true">
                  <TableHeader className="sticky top-0 z-10">
                    <TableRow>
                      {[
                        "Name",
                        "File Name",
                        "Status",
                        "Total Rows",
                        "Date Processed",
                        "Source",
                        "Actions",
                      ].map((heading, index) => (
                        <TableHead key={`previous-uploads-header-${index}`}>
                          {heading}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {previousImports?.map((row, index) => (
                      <TableRow key={`map-fields-${index}`}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.file_name}</TableCell>
                        <TableCell>
                          <div className="inline-block">
                            <Status status={row.status} />
                          </div>
                        </TableCell>
                        <TableCell>{row.total_records}</TableCell>
                        <TableCell>
                          {formatDate(row.create_timestamp) || "--"}
                        </TableCell>
                        <TableCell>{row.source}</TableCell>
                        <TableCell>
                          {row.status === UPLOAD_STATUSES.complete && (
                            <div className="flex gap-2">
                              {createViewListingsButton(
                                row,
                                index === 0 && !isMobile
                              )}
                              {createDownloadListingsButton(
                                row,
                                index === 0 && !isMobile
                              )}
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              {/* Mobile */}
              <div className=" md:hidden flex flex-col gap-1 w-full overflow-x-auto mt-8  bg-slate-400 overscroll-none">
                {previousImports?.map((row, index) => (
                  <div
                    key={`map-fields-mobile-${index}`}
                    className="card min-h-36 w-full bg-white pt-2 pb-4"
                  >
                    <div className=" body flex justify-between px-2 gap-10">
                      <div className="flex row1 flex-col gap-3">
                        <div className=" justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px] leading-5 text-[#999] ">
                            Name
                          </p>
                          <p className="text-[18px] font-semibold  leading-5 text-[#666666] ">
                            {row.name}
                          </p>
                        </div>
                        <div className=" justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px]  leading-5 text-[#999]  ">
                            File Name
                          </p>
                          <p className="text-[18px] font-semibold  leading-5 text-[#666666] ">
                            {row.file_name}
                          </p>
                        </div>

                        <div className=" justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px] font-semibold leading-5 text-[#999]  ">
                            Status
                          </p>
                          <p className="text-[18px] text-left font-semibold  leading-5 text-[#666666] ">
                            <div className="inline-block">
                              <Status status={row.status} />
                            </div>
                          </p>
                        </div>
                      </div>

                      <div className="flex row2 flex-col gap-3">
                        <div className=" justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px] leading-5 text-[#999]  ">
                            Total Rows
                          </p>
                          <p className="text-[18px] font-semibold  leading-5 text-[#666666] ">
                            {row.total_records}
                          </p>
                        </div>
                        <div className=" justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px]  leading-5 text-[#999]  ">
                            Date Added
                          </p>
                          <p className="text-[18px] font-semibold  leading-5 text-[#666666] ">
                            {row.complete_date}
                          </p>
                        </div>

                        <div className=" justify-between gap-3  min-w-[40px]">
                          <p className="text-[14px]leading-5 text-[#999]  ">
                            Source
                          </p>
                          <p className="text-[18px] font-semibold  leading-5 text-[#666666] ">
                            {row.source}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" flex justify-between mt-4">
                      {createViewListingsButton(row, index === 0 && isMobile)}
                      {createDownloadListingsButton(
                        row,
                        index === 0 && isMobile
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center mt-4">
                <PageSize
                  value={{ id: pageSize, label: pageSize, value: pageSize }}
                  onChange={(option) => {
                    setPageSize(option.value);
                    setCurrentPage(1);
                  }}
                />
                <Pagination
                  numRecords={totalRecords}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={(updatedPage) => {
                    setCurrentPage(updatedPage);
                  }}
                />
              </div>
            </>
          )}
        </section>
      ) : (
        <NoAccessPage product="Data Genie" />
      )}
    </PageLayout>
  );
};

export default PreviousImports;
