export const STEPS = {
  configureUpload: 1,
  mapFields: 2,
  review: 3,
  submitted: 4,
};

export const STEPS_CONFIG = [
  { name: STEPS.configureUpload, pageTitle: "Start Lookup", breadcrumbLabel: "Start" },
  { name: STEPS.mapFields, pageTitle: "Map Your Fields", breadcrumbLabel: "Map Fields" },
  { name: STEPS.review, pageTitle: "Review & Submit", breadcrumbLabel: "Review" }
];

export const LOOKUP_TYPES = {
  reverseAddress: "A",
  reversePhone: "P",
};

export const CONTACT_MORE_CREDITS = "801-845-4383 "