/**
 * Formats a number according to the "en-US" locale with specified fraction digits.
 * Returns a default output if the input is not a valid number.
 *
 * @param value - The value to format.
 * @param defaultOutput - The string to return if the value is not a valid number.
 * @returns The formatted number as a string or the default output.
 */
export function formatNumber(value: unknown, defaultOutput: string): string {

  // Check if the value is a number or can be converted to a number
  const numberValue = typeof value === "number" ? value : Number(value);

  // Validate that the parsed value is a finite number
  if (isNaN(numberValue) || !isFinite(numberValue)) {
    return defaultOutput;
  }

  // Format the number using toLocaleString with specified options
  return numberValue.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

export function isValidNumber(value: number | string | undefined | null): boolean {
  if (typeof value === 'string') {
    value = value.trim();
  }
  return value !== null && value !== undefined && value !== '' && !isNaN(Number(value));
}

export function formatAsCurrency(value:string | number | null | undefined, defaultOutput: string): string {

  if (!isValidNumber(value)) {
    return defaultOutput || '$0.00';
  }

  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

}

// Example usage:
// const exampleString = "The population is 1234567 and the area is 98765.432 square miles.";
// const formattedString = formatNumbersInString(exampleString);
// console.log(formattedString);
// Output: "The population is 1,234,567 and the area is 98,765.432 square miles."
export function formatNumbersInString(input: string, locale = 'en-US'): string {
  // Regular expression to match numbers, including integers and decimals
  const numberRegex = /-?\d+(\.\d+)?/g;

  // Function to format a single number string
  const formatNumber = (numStr: string): string => {
      const num = parseFloat(numStr);
      if (isNaN(num)) return numStr; // Return original string if it's not a valid number
      return new Intl.NumberFormat(locale).format(num);
  };

  // Replace each number in the input string with its formatted version
  return input.replace(numberRegex, match => formatNumber(match));
}


