import { useQuery } from "@tanstack/react-query";
import fetcher from "../utils/fetcher";
import { objectToSearchParams } from "src/utils/queryString";

export const useExpiredUploads = (
  { pageSize = 25, currentPage = 1 } = { pageSize: 25, currentPage: 1 }
) => {
  const queryString = objectToSearchParams({
    page_size: pageSize,
    offset: (currentPage - 1) * pageSize,
  }).toString();
  return useQuery({
    queryKey: ["expiredUploads", queryString],
    queryFn: async () => {
      const resp = await fetcher({
        url: `/api/expired/?${queryString}`,
      });
      return {
        credits: resp.data?.credits || 0,
        uploads: resp.data?.batches || [],
        totalRecords: resp.data?.total_records || 0,
      };
    },
  });
};

export default useExpiredUploads;
