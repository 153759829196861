import Button, { ButtonProps } from "./Button";
import { Link } from "react-router-dom";

const LinkButton = ({
  to,
  children,
  ...otherProps
}: ButtonProps & { to: string }) => (
  <Link to={to}>
    <Button {...otherProps}>{children}</Button>
  </Link>
);

export default LinkButton;
