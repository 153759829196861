import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const Map = ({ mapCenter, children }) => (
  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      {...(mapCenter && { center: mapCenter })}
      zoom={15}
      options={{
        gestureHandling: 'greedy',
        zoomControl: true,
        zoomControlOptions: {
          position: window?.google?.maps?.ControlPosition?.RIGHT_TOP
        }
      }}
    >
      {children}
    </GoogleMap>
  </LoadScript>
);

export default Map;
