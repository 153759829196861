import { ReactNode } from "react";
import {
    TableCell as BaseTableCell,
    TableHead as BaseTableHead,
    TableHeader as BaseTableHeader,
} from "../shadcn";

export const TableCell = ({ children, className = "" }: { children: ReactNode, className: string }) => (
    <BaseTableCell className={`py-2 text-[#666] ${className || ""}`}>
        {children}
    </BaseTableCell>
);

export const TableHeader = ({ children, className = "" }: { children: ReactNode, className: string }) => (
    <BaseTableHeader className={`bg-gray-100 ${className || ""}`}>{children} </BaseTableHeader>
);

export const TableHead = ({ children }: { children: ReactNode }) => (
    <BaseTableHead className="text-sm font-semibold text-[#666666]">
        {children}{" "}
    </BaseTableHead>
);

export { Table, TableBody, TableRow } from "../shadcn";
