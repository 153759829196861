import ListingDetails from "./ListingDetails";
import useListingDetails from "../../hooks/useListingDetails";
import { Mail, Editbutton } from "@landvoice/icons";
import {
  HDivider,
  InfoText,
  formatDate,
  formatPhoneNumber,
} from "../../components/common/amenities";
import HorizonalDivider from "../common/HorizonalDivider";
import { SUPPORTED_SOCIAL_MEDIA } from "../../constants/listing";
import Socials from "../common/Socials";
import { pick } from "lodash";
import ContactCard from "../common/ContactCard";
import DetailsField from "./DetailsField";
import {
  getExpiredListingStatus,
  getListingStatusDate,
} from "../../utils/listings";
import { PropertyTypeEnum } from "../../data/selectionData";
import {
  getListingPrice,
  getFormattedPrice,
  getExpiredListingTitle,
  getPropertyId,
} from "../../utils/listings";
const ExpiredDetails = ({
  onClose,
  productType,
  isOld = false,
  listingId,
  onTagsSaved = (updatedTags) => {},
  onNotesSaved = (updatedNotes) => {},
  onMapButtonClick,
  onNextListingClick,
  onPreviousListingClick,
}) => {
  const listingDetails = useListingDetails({
    productType,
    onTagsSaved,
    onNotesSaved,
    apiUrl: isOld
      ? `/api/oldexpired/listing/${listingId}`
      : `/api/expired/listing/${listingId}`,
  });

  const {
    listing,
    toggleNotesDialog,
    toggleTagsDialog,
    showFullNote,
    setShowFullNote,
    processedContacts,
    isLoading,
    isError,
  } = listingDetails;

  const socials = pick(
    {
      ...listing?.social_media,
    },
    SUPPORTED_SOCIAL_MEDIA
  );

  const hasSocials = !!Object.values(socials).length;

  return (
    <ListingDetails
      onClose={onClose}
      onNextListingClick={onNextListingClick}
      onPreviousListingClick={onPreviousListingClick}
      title={isOld ? "Old Expired" : "Expired"}
      listingDetails={listingDetails}
      AddNotesProps={{
        where: isOld ? "oldexpired" : "expired",
        postTo: isOld
          ? `/api/oldexpired/listing/${listingId}`
          : `/api/expired/listing/${listingId}`,
      }}
      AddTagsProps={{
        postTo: isOld
          ? `/api/oldexpired/listing/${listingId}/tags`
          : `/api/expired/listing/${listingId}/tags`,
      }}
      PrintOneViewPropsProvider={(listing) => ({
        title: getExpiredListingTitle(listing),
        bath: listing?.baths || listing?.bathrooms,
        beds: listing?.bedrooms,
        sqrFeet: listing?.square_feet || listing?.square_footage,
        lotSize: listing?.acres || listing?.property_size,
        buildDate: listing?.year_built,
        type: PropertyTypeEnum[listing?.property_type],
        statusDate: getListingStatusDate(listing),
        status: getExpiredListingStatus(listing),
        where: "Expired",
        price: getFormattedPrice(getListingPrice(listing)),
      })}
      mlsTitleAmenitiesPropsProvider={(listing) => ({
        all: false,
        status: getExpiredListingStatus(listing),
        title: getExpiredListingTitle(listing),
        bath: listing?.baths || listing?.bathrooms,
        beds: listing?.bedrooms,
        sqrFeet: listing?.square_feet || listing?.square_footage,
        lotSize: listing?.acres || listing?.property_size,
        buildDate: listing?.year_built,
        type: PropertyTypeEnum[listing?.property_type],
        isDetails: true,
        mapLink: `https://www.google.com/maps/place/${listing?.property?.street_address}+${listing?.property?.city}+${listing?.property?.state}+${listing?.property?.postal_code}`,
        onMapButtonClick,
      })}
    >
      {!isLoading && !isError && (
        <>
          <div>
            <div>
              <HDivider />
              <div className="flex gap-[40px] flex-wrap items-center py-[auto] my-2 print:py-0 print:my-0">
                <InfoText
                  title={"Price"}
                  info={
                    getListingPrice(listing)
                      ? getFormattedPrice(getListingPrice(listing))
                      : "--"
                  }
                />
                <InfoText title="DOM" info={listing?.days_on_market} />
                <InfoText title="Landvoice ID" info={listing?.id} />
                <InfoText
                  title="Date Processed"
                  info={formatDate(listing?.publish_date)}
                />
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-12 mt-12 print:mt-2 print:gap-3">
            <section>
              <div className="md:max-w-[729px] flex gap-8 flex-col md:flex-row print:flex-row">
                <div className="  w-full ">
                  <div className="flex gap-4 h-7 items-center   w-full mb-6">
                    <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                      Notes{" "}
                      {listing?.notes?.length > 0 ? (
                        <span className="font-[500] text-[12px] text-[#999999]">
                          Last modified{" "}
                          {formatDate(listing?.notes[0]?.modify_date)}
                        </span>
                      ) : (
                        <span className="font-[500] text-[12px] text-[#999999]">
                          no notes were added
                        </span>
                      )}
                    </p>{" "}
                    <HorizonalDivider className="min-w-[25px]" />
                    <div
                      onClick={() =>
                        toggleNotesDialog({ note: listing?.notes })
                      }
                      className="w-[20px] cursor-pointer"
                    >
                      <Editbutton className="text-[20px] w-5 h-5" />
                    </div>
                  </div>
                  {listing?.notes?.length > 0 ? (
                    <>
                      <pre
                        className={`text-[12px] leading-[15px] text-[#666666] cursor-pointer font-[Lato] text-wrap  `}
                      >
                        <span
                          className={`${showFullNote ? "" : "line-clamp-3"}`}
                        >
                          {listing?.notes[0]?.note}{" "}
                        </span>{" "}
                        {listing?.notes[0]?.note.length > 195 && (
                          <span
                            className="font-[500] text-[12px] text-[#999999] italic"
                            onClick={() => {
                              setShowFullNote((cur) => !cur);
                            }}
                          >
                            {showFullNote ? "... see less" : "... see more"}
                          </span>
                        )}
                      </pre>

                      {listing?.notes.length > 1 &&
                        listing?.notes[0]?.note?.length < 80 && (
                          <pre
                            className={`text-[12px] leading-[15px] text-[#666666] cursor-pointer font-[Lato] text-wrap   `}
                          >
                            <span
                              className={`${
                                showFullNote ? "wrap text-wrap" : "line-clamp-3"
                              }`}
                            >
                              {listing?.notes[1]?.note}{" "}
                            </span>{" "}
                            {listing?.notes[1]?.note.length > 195 && (
                              <span
                                className="font-[500] text-[12px] text-[#999999] italic"
                                onClick={() => {
                                  setShowFullNote((cur) => !cur);
                                }}
                              >
                                {showFullNote ? "... see less" : "... see more"}
                              </span>
                            )}
                          </pre>
                        )}
                    </>
                  ) : (
                    <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                      No notes added.{" "}
                      <span
                        onClick={toggleNotesDialog}
                        className=" underline cursor-pointer"
                      >
                        Click to add notes.
                      </span>
                    </p>
                  )}
                </div>

                <div className=" max-w-[260px] ">
                  <div className="flex w-full gap-4 h-7 items-center mb-6">
                    <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                      Tags{" "}
                    </p>{" "}
                    <HorizonalDivider className="min-w-[200px]" />
                    <div onClick={toggleTagsDialog} className="w-[20px]">
                      <Editbutton className="text-[20px] w-5 h-5" />
                    </div>
                  </div>
                  {listing?.tags?.length ? (
                    <div className=" flex flex-wrap gap-2">
                      {listing?.tags?.map((tag, index) => {
                        return (
                          <div
                            key={`tag-${tag}-${index}`}
                            className="flex items-center justify-between h-6 min-w-[50px] bg-[#EBEBEB] px-4 gap-1 rounded-[15.56px]"
                          >
                            <p className="text-[11px] font-bold text-[#666666] leading-4">
                              {tag}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                      No tags found.{" "}
                      <span
                        onClick={toggleTagsDialog}
                        className=" underline cursor-pointer"
                      >
                        Click to add tags.
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </section>

            <section>
              <div className="max-w-[729px] min-h-[140px]">
                <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                    Owner Information{" "}
                    <span className="font-[500] text-[12px] text-[#999999]"></span>
                  </p>{" "}
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <DetailsField
                    fieldLabel="Name"
                    fieldValue={listing?.owner_information?.full_name}
                    noValueDisplay="No Information found"
                  />
                  <DetailsField
                    fieldLabel="Address"
                    fieldValue={
                      listing?.owner_information?.property?.street_address
                        ? `${listing?.owner_information?.property?.street_address}, ${listing?.owner_information?.property.city}, ${listing?.owner_information?.property?.state} ${listing?.owner_information?.property?.postal_code}`
                        : ""
                    }
                  />
                  <div className="flex flex-col">
                    <p className=" font-semibold text-[14px] leading-5 text-[#999999] ">
                      Email and Socials
                    </p>
                    <div className="flex gap-3 items-center">
                      <Mail className="text-[16px]" />{" "}
                      {listing?.social_media?.email ? (
                        <p className="  text-[14px] leading-5 text-[#666666] ">
                          {listing?.social_media?.email}
                        </p>
                      ) : (
                        <p className="  text-[14px] leading-5 text-[#666666] italic">
                          No email found
                        </p>
                      )}
                    </div>
                    {hasSocials && (
                      <div className="mt-2">
                        <HDivider />
                        <div className="h-4 mt-2 flex gap-[14px]">
                          <Socials {...socials} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {processedContacts?.length > 0 && (
              <section>
                <div className="max-w-[729px]">
                  <div className="flex gap-4 h-7 items-center mb-6 print:mb-0">
                    <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                      Phone Numbers
                      <span className="font-[500] text-[12px] text-[#999999]"></span>
                    </p>{" "}
                    <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                  </div>
                  <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                    {processedContacts?.map((contact, index) => (
                      <ContactCard
                        key={`contact-card-${contact.id}`}
                        name={
                          [contact?.first_name, contact?.last_name].join(" ") ||
                          ""
                        }
                        number={contact?.phone}
                        dnc={contact?.phone_dnc}
                        type={contact?.phone_type}
                        icon="Owner"
                        isStarred={contact?.star}
                        hidden={contact?.hidden}
                        list={isOld ? "oldexpired" : "expired"}
                        propertyId={getPropertyId(listing)}
                      />
                    ))}
                  </div>
                </div>
              </section>
            )}

            <section>
              <div className="text-[#666666]">
                <div className="flex gap-4 h-7 items-center mb-6 print:mb-0 ">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6">
                    MLS Information{" "}
                    <span className="font-[500] text-[12px]"></span>
                  </p>{" "}
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <DetailsField
                    fieldLabel="MLS ID"
                    fieldValue={listing?.mls_number}
                  />
                  <DetailsField
                    fieldLabel="Status"
                    fieldValue={getExpiredListingStatus(listing)}
                  />
                  <DetailsField
                    fieldLabel="Status Date"
                    fieldValue={formatDate(listing?.status_date)}
                  />
                  <DetailsField
                    fieldLabel="Agent"
                    fieldValue={listing?.list_agent}
                  />
                  <DetailsField
                    fieldLabel="Phone"
                    fieldValue={formatPhoneNumber(listing?.list_agent_phone)}
                  />
                  <DetailsField
                    fieldLabel="Company"
                    fieldValue={listing?.list_agent_company}
                  />
                </div>
              </div>
            </section>

            <section>
              <div className="text-[#666666]">
                <div className="flex gap-4 h-7 items-center mb-6 print:mb-0">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6">
                    Tax Information
                  </p>{" "}
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <DetailsField
                    fieldLabel="Owner Name"
                    fieldValue={`${listing?.owner_information?.full_name}`}
                    noValueDisplay="Owner not found"
                  />
                  <DetailsField
                    fieldLabel="Phone Number"
                    fieldValue={listing?.contacts[0]?.phone}
                    noValueDisplay="Owner number not found"
                  />
                  <DetailsField
                    fieldLabel="Mailing Address"
                    fieldValue={
                      listing?.owner_information?.property.street_address
                        ? `${listing?.owner_information?.property.street_address}, ${listing?.owner_information?.property.city}, ${listing?.owner_information?.property.state} ${listing?.owner_information?.property.postal_code}`
                        : ""
                    }
                    noValueDisplay="Owner address not found"
                  />
                  <DetailsField
                    fieldLabel="Tax ID"
                    fieldValue={listing?.tax_id}
                    noValueDisplay="Owner address not found"
                  />
                  <DetailsField
                    fieldLabel="Owner Occupied"
                    fieldValue={
                      listing?.owner_information?.owner_occupied ? "Yes" : "No"
                    }
                    noValueDisplay=""
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="text-[#666666]">
                <div className="flex gap-4 h-7 items-center mb-6 print:mb-0">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6">
                    Agent Remarks from MLS
                  </p>{" "}
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>

                <p className="text-[14px] text-[#666666] leading-[21px] print:text-[10px] print:leading-3 ">
                  {listing?.comments}
                </p>
              </div>
            </section>

            <section>
              <div className="text-[#666666]">
                <div className="flex gap-4 h-7 items-center mb-6 print:mb-0">
                  <p className="whitespace-nowrap text-[18px] font-bold leading-6">
                    Showing Instructions
                  </p>{" "}
                  <HorizonalDivider className="max-w-[562px]  min-w-[25px]" />
                </div>

                <p className="text-[14px] leading-[21px] print:text-[10px] print:leading-3 ">
                  {listing?.showing_instructions}
                </p>
              </div>
            </section>
          </div>
        </>
      )}
    </ListingDetails>
  );
};

export default ExpiredDetails;
