import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Manage from "src/components/expireds/Manage";
import Search from "src/components/expireds/Search";

const ExpiredsPage = () => {
  return (
    <>
      <Routes>
        <Route path="search" element={<Search />} />
        <Route path="manage" element={<Manage />} />
        <Route index element={<Navigate to="search" />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default ExpiredsPage;
