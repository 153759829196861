import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import { useAuth } from "../auth/assist/authContext";
import { LiaHatWizardSolid } from "react-icons/lia";
import { IconWrapper } from "./IconWrapper";
import logoutIcon from "../../../assets/right-from-bracket-solid.svg";
import logo from "../../../assets/Landvoice Logo 2.png";
import Xmark from "../../../assets/xmark.svg";
import Bars from "../../../assets/bars.svg";
import { addOns, coreProducts } from "./SidebarData";
import {
  MOBILE_SIDEBAR_OVERLAY,
  MOBILE_SIDEBAR,
} from "../../../constants/zIndexes";

const MobileView = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const { logout, user } = useAuth();

  return (
    <>
      <div
        className="landvoice-logo logo-M bg-[#212a33] print:bg-transparent print:hidden print:justify-end"
        style={{
          zIndex: MOBILE_SIDEBAR,
        }}
      >
        <img
          height={"24px"}
          width={"24px"}
          src={!sidebar ? Bars : Xmark}
          onClick={showSidebar}
          alt=""
        />
        <img height={"24px"} width={"24px"} src={logo} alt="" />{" "}
        <p className={" print:text-[#000000]"}>
          LAND<span>VOICE</span>
        </p>
      </div>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className=" relative">
          {sidebar && (
            <div
              onClick={showSidebar}
              className="h-screen w-screen fixed  bg-slate-500 opacity-10 z-0  "
            />
          )}
          <nav
            className={sidebar ? "nav-menu-M open  relative" : "nav-menu-M"}
            style={{
              zIndex: MOBILE_SIDEBAR_OVERLAY,
            }}
          >
            <ul className="nav-menu-items overflow-y-scroll z-10">
              {coreProducts.map(({ title, path, icon }, index) => (
                <li>
                  <NavLink to={path} className={"nav-text"}>
                    <div className="nav-button">
                      <IconWrapper>{icon}</IconWrapper>
                      <span>{title}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
              <p className={"nav-text title-M"}>Add Ons</p>
              {addOns.map(({ title, path, icon }, index) => (
                <li>
                  <NavLink to={path} className={"nav-text"}>
                    <div className="nav-button">
                      <IconWrapper>{icon}</IconWrapper>
                      <span>{title}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>

            <ul className="flex flex-col my-4">
              <li>
                <NavLink
                  to="https://www.landvoice.com/help"
                  className={"nav-text"}
                  component="li"
                >
                  <div className="nav-button">
                    <IconWrapper>
                      <FaIcons.FaQuestion />
                    </IconWrapper>
                    <span>Help</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/accountsettings" className={"nav-text"}>
                  <div className="nav-button">
                    <IconWrapper>
                      <FaIcons.FaCog />
                    </IconWrapper>
                    <span>Account settings</span>
                  </div>
                </NavLink>
              </li>
              <li className={"nav-text light-hover"}>
                <Link to="#" onClick={logout}>
                  <img src={logoutIcon} alt=""></img>
                  <span>Log Out</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default MobileView;
